<!-- <app-home-slider></app-home-slider> -->
<app-slider2></app-slider2>

<div class="content-block">

    <!-- <div class="row" style="display: flex; justify-content: center;align-items: center;margin-top: 1rem;">
        <dialog #dialog id="dialog2" style="width: 65%;">
            <div style="text-align: right;font-size: 30px;margin-bottom: 10px;">
                <a style="color: red;text-align: right;cursor: pointer;" (click)="dialog.close()">
                    <i class="fa fa-close"></i>
                </a>
            </div>
            <div>
                <img src="../../assets/images/addmission_banner_2024.jpg" >
            </div>
        </dialog>

        <button class="btn btn-success" (click)="dialog.showModal()">BE FIRST YEAR ADMISSION 2023-2024</button>
    </div> -->

    <app-home-about [data]="skills"></app-home-about>

    <app-home-mentor></app-home-mentor>

    <app-home-team></app-home-team>

    <app-home-courses></app-home-courses>

    <app-home-principal></app-home-principal>

    <app-upcoming-events></app-upcoming-events>

    <app-home-vision-mission></app-home-vision-mission>

    <!-- <app-home-counter></app-home-counter> -->

    <app-home-eminient></app-home-eminient>

    <app-home-youtube></app-home-youtube>

    <app-home-client></app-home-client>

    <!-- <app-home-testimonial></app-home-testimonial> -->
</div>