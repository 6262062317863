<div class="page-wraper">
    <app-header></app-header>

    <div class="page-content bg-white">
        <router-outlet></router-outlet>
    </div>

    <app-footer></app-footer>
    <button class="scroltop icon-up" type="button"><i class="fa fa-arrow-up"></i></button>
</div>


<!-- Existing #F29727 (2) #F29727 (3) #F29727-->
<!-- https://www.freepik.com/search?color=orange&format=search&page=5&query=website+background+texture -->