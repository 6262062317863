<div class="content-block">
    <app-header-banner [data]="banner"></app-header-banner>
    <div class="container" style="margin-top: 10px;background:#fff;border-radius:15px;padding:40px">
        <div class="row">
            <div class="col-md-12">
                <div class="di"><b>Name of Participant:</b> Navneet Sahu<br>
                    <hr><b>Year:</b> 2019<br>
                    <hr><b>Sport Activity:</b> Won Bronze medal at All India Inter university Water Polo Championship at
                    Banglore<br>
                    <hr><b>Position:</b> 3<sup>rd</sup><br><br>
                    <div class="container">
                        <div class="row">
                            <div class="col-md-4"><a href="http://www.prpotepatilpharma.ac.in/img/gl/z1.jpg" class="link-preview" data-lightbox="nime"
                                    data-title="Awards &amp; Accolades" title="Preview"><img src="http://www.prpotepatilpharma.ac.in/img/gl/z1.jpg"
                                        class="img-fluid link-preview"></a></div>
                            <div class="col-md-4"><a href="http://www.prpotepatilpharma.ac.in/img/gl/z2.jpg" class="link-preview" data-lightbox="nime"
                                    data-title="Awards &amp; Accolades" title="Preview"><img src="http://www.prpotepatilpharma.ac.in/img/gl/z2.jpg"
                                        class="img-fluid link-preview"></a></div>
                            <div class="col-md-4"><a href="http://www.prpotepatilpharma.ac.in/img/gl/z3.jpg" class="link-preview" data-lightbox="nime"
                                    data-title="Awards &amp; Accolades" title="Preview"><img src="http://www.prpotepatilpharma.ac.in/img/gl/z3.jpg"
                                        class="img-fluid link-preview"></a></div>
                        </div>
                    </div><br>
                </div>
            </div>
        </div>
    </div>
</div>