<div class="section-full content-inner wow fadeIn" data-wow-delay="0.4s">
	<div class="container">
		<div class="section-head m-b20">
            <h2 class="title" style="color: #F24C3D;font-family: Rockwell !important;font-weight: 500;">Our Alumni Testimonials</h2>
            <div class="dlab-separator bg-primary" style="background-color: #d6000059 !important;"></div>
        </div>
		<div class="section-content m-b30 row">
			<div class="testimonial-six owl-loaded owl-theme owl-carousel owl-none dots-style-2">
				<div class="item">
					<div class="testimonial-8">
						<div class="testimonial-text">
							<p>It’s my immense pleasure to become a part of the P. R. Pote Patil College of
								Engineering and Management, for making my career successfully. One of the most amazing
								things given by campus in my graduation days is [...]</p>
						</div>
						<div class="testimonial-detail clearfix">
							<div class="testimonial-pic radius shadow"><img src="assets/images/testimonials/1.jpg"
									width="100" height="100" alt=""></div>
							<h5 class="testimonial-name m-t0 m-b5">Er. Ashish Kotwal</h5> <span
								class="testimonial-position">Private Civil Engg. Consultant</span>
						</div>
					</div>
				</div>
				<div class="item">
					<div class="testimonial-8">
						<div class="testimonial-text">
							<p>In . P. R. Pote Patil College of Engineering & Management, Amravati, it tremendously
								improved my leadership and inter-personal skills. I got to meet some wonderful people in
								my life and have a great learning experience [...]</p>
						</div>
						<div class="testimonial-detail clearfix">
							<div class="testimonial-pic radius shadow"><img src="assets/images/testimonials/2.jpg"
									width="100" height="100" alt=""></div>
							<h5 class="testimonial-name m-t0 m-b5">Mr. Avinash Sukhdeve</h5> <span
								class="testimonial-position">Sr.Team Leader, Vivo India </span>
						</div>
					</div>
				</div>
				<div class="item">
					<div class="testimonial-8">
						<div class="testimonial-text">
							<p>The first moment I entered at P. R. Pote Patil College of Engineering and Management, I
								met with nothing but energy and enthusiasm. The faculty and staff at this institution
								are helpful and cooperative. They [...]</p>
						</div>
						<div class="testimonial-detail clearfix">
							<div class="testimonial-pic radius shadow"><img src="assets/images/testimonials/3.jpg"
									width="100" height="100" alt=""></div>
							<h5 class="testimonial-name m-t0 m-b5">Mr. Mandar Deshmukh </h5> <span
								class="testimonial-position">Sr.Unit Manager, Bajaj Finserv</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>