<style>
    @import url('https://fonts.cdnfonts.com/css/rockwell-nova');
</style>

<header class="site-header mo-left header ext-header navstyle1">
    <!-- style="z-index: 1" -->
    <div class="middle-bar bg-white" style="padding: 10px 0">
        <div class="container ">
            <!-- website logo  -->
            <div class="middle-area">
                <div class="logo-header logo-dark">
                    <a [routerLink]="['/']"><img src="../../assets/images/pote_pharmacy_logo.png" style="width: 125px"
                            alt=""></a>
                </div>
                <div class="service-list"
                    style="line-height: 2.2rem !important; padding-left: 1rem; border-left: 5px solid #F24C3D;">
                    <div style="text-align: left;">
                        <!-- #a83e20 -->
                        <div
                            style="color: #F24C3D; font-size: 2.7rem; font-weight: 500;letter-spacing: 1px;font-family: Rockwell !important;">
                            P. R. Pote Patil </div>
                        <div
                            style="color: #001b5c; font-size: 32px; font-weight: 600;letter-spacing: 1px;font-family: Rockwell !important;">
                            College of
                            Pharmacy, Amravati</div>
                        <div
                            style="font-size: 13px; font-weight: 600;line-height: 1.7rem;font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;">
                            Recognized by PCI New Delhi, DTE and Affiliated to SGBAU & MSBTE
                        </div>
                        <div style="padding: 1rem 3rem 0 0;">
                            <h6 class="bot-bizo heart"><i>"Quality Education is our Zeal"</i></h6>
                        </div>
                        <!-- <div  style="font-size: 12px; font-weight: 500;"> Certified by ISO 9001
                            &amp; ISO 14001 </div> -->
                    </div>
                </div>
                <div class="logo-header logo-dark" style="display: flex;justify-content: right;align-items: center;">
                    <img src="assets/images/pharm.png" style="width: 160px" alt="">
                </div>
            </div>
        </div>
    </div>
    <!-- main header -->
    <div class="sticky-header main-bar-wraper navbar-expand-lg">
        <div class="main-bar clearfix ">
            <div class="container container_new clearfix">
                <!-- website logo -->
                <div class="logo-header mostion logo-dark">
                    <a [routerLink]="['/']"><img src="assets/images/prp_logo.jpeg" style="width: 3rem;"
                            alt=""></a>
                </div>
                <!-- nav toggle button -->
                <button class="navbar-toggler collapsed navicon justify-content-end" type="button"
                    data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
                    aria-expanded="false" aria-label="Toggle navigation">
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
                <!-- extra nav -->
                <!-- <div class="extra-nav">
                    <div class="extra-cell">
                        <button id="quik-search-btn" type="button" class="site-button-link"><i
                                class="la la-search"></i></button>
                    </div>
                </div>
                <div class="dlab-quik-search ">
                    <form action="#">
                        <input name="search" value="" type="text" class="form-control" placeholder="Type to search">
                        <span id="quik-search-remove"><i class="ti-close"></i></span>
                    </form>
                </div> -->
                <!-- main nav -->
                <div class="header-nav navbar-collapse collapse justify-content-start" id="navbarNavDropdown">
                    <div class="logo-header d-md-block d-lg-none">
                        <a [routerLink]="['/home-university']"><img src="assets/images/logo-2.png" alt=""></a>
                    </div>
                    <ul class="nav navbar-nav">
                        <li class="active has-mega-menu homedemo">
                            <a href="/">Home</a>
                        </li>
                        <li>
                            <a href="javascript:void(0);">About<i class="fa fa-chevron-down"></i></a>
                            <ul class="sub-menu tab-content">
                                <li><a [routerLink]="['/about-college']">About College</a></li>
                                <li><a [routerLink]="['/salient']">Salient Features</a></li>
                                <li><a [routerLink]="['/rules']">Rules of Conduct</a></li>
                                <li><a [routerLink]="['/governing-body']">Governing Body</a></li>
                                <li><a [routerLink]="['/development-committee']">College Development Committee</a></li>
                                <!-- <li><a [routerLink]="['/organisation-chart']">Organisation Chart</a></li> -->
                                <li><a [routerLink]="['/approvals']">Approvals</a></li>
                            </ul>
                        </li>
                        <li>
                            <a href="javascript:void(0);">Admission<i class="fa fa-chevron-down"></i></a>
                            <ul class="sub-menu">
                                <li>
                                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSfRmjHyW-ydotczDfBD4OOn5CDI_GuYablher7CEBeia-9_8Q/viewform?pli=1"
                                        target="_blank">
                                        Admission Enquiry
                                    </a>
                                </li>

                                <li><a [routerLink]="['/admission/first-year']">Admission Notice</a></li>
                                <li><a target="_blank" href="../../assets/pdf/cutoff/B Pharm Cut 1.pdf">B. Pharm Merit
                                        List 2022-23</a></li>
                                <li><a target="_blank" href="../../assets/pdf/cutoff/DSP.pdf">B. Pharm Merit List
                                        2022-23 DSP</a></li>
                                <li><a target="_blank" href="../../assets/pdf/cutoff/D Pharm CAP I.pdf">D. Pharm Merit
                                        List 2022-23</a></li>
                            </ul>
                        </li>
                        <li>
                            <a href="javascript:void(0);">Courses<i class="fa fa-chevron-down"></i></a>
                            <ul class="sub-menu tab-content">
                                <li><a [routerLink]="['/dpharm']">D. Pharm</a></li>
                                <li><a [routerLink]="['/bpharm']">B. Pharm</a></li>
                                <li><a >M. Pharm</a></li>
                            </ul>
                        </li>

                        <li>
                            <a href="javascript:void(0);">R & D<i class="fa fa-chevron-down"></i></a>
                            <ul class="sub-menu tab-content">
                                <li><a [routerLink]="['/r-and-d']">Research & Development</a></li>
                                <li><a [routerLink]="['/Publication-faculty']">Publication (Faculty)</a></li>
                                <li><a [routerLink]="['/Publication-student']">Publication (Student)</a></li>
                                <li><a [routerLink]="['/patents']">Patents</a></li>
                                <li><a [routerLink]="['/poster-presentation']">Poster Presentation</a></li>
                                <li><a [routerLink]="['/oral-presentation']">Oral Presentation</a></li>
                                <li><a [routerLink]="['/awards-accolades']">Awards & Accolades</a></li>
                            </ul>
                        </li>

                        <li>
                            <a href="javascript:void(0);">Students<i class="fa fa-chevron-down"></i></a>
                            <ul class="sub-menu tab-content">
                                <li><a [routerLink]="['/student-council']">Student Council</a></li>
                                <li><a [routerLink]="['/student-enrolled']">Student Enrolled</a></li>
                                <div style="margin: 10px 0 0; font-weight: 600; font-size: 12px; margin-left: 1rem; color: black; padding: 10px;background-color: rgb(224, 224, 224);">
                                    Student Support
                                </div>
                                <li style="padding-left: 1rem;" class=""><a [routerLink]="['/grc']">Gerievance Redressal Cell</a></li>
                                <li style="padding-left: 1rem;"><a [routerLink]="['/icc']">Internal Complaints Committee/<br>Gender Sensitization Cell</a></li>
                                <li style="padding-left: 1rem;"><a [routerLink]="['/anti-ragging']">Antiragging Committee</a></li>
                                <li style="padding-left: 1rem;"><a [routerLink]="['/anti-disc']">Anti-discrimination cell</a></li>
                                <li style="padding-left: 1rem;"><a [routerLink]="['/sc-st']">SC/ST Cell</a></li>
                            </ul>
                        </li>
                        <li>
                            <a href="javascript:void(0);">Student Welfare<i class="fa fa-chevron-down"></i></a>
                            <ul class="sub-menu tab-content">
                                <li><a [routerLink]="['/activities/committees']">Committees</a></li>
                                <li><a href="https://www.prpotepatilengg.ac.in/IQAC/Other_Files/RTI.pdf"
                                        target="_blank">RTI</a></li>
                                <li><a [routerLink]="['/activities/techelons']">Tachelons</a></li>
                                <li><a [routerLink]="['/activities/foreign-tour']">Foreign Tour</a></li>
                                <li><a href="http://prpotepatilengg.logixspire.com/wp-content/uploads/2020/12/Parents-Meet-2021-2022.pdf"
                                        target="_blank">Parents Meet 2022-23</a></li>
                                <li><a [routerLink]="['/activities/festival-celebration']">Festival Celebration</a></li>
                                <li><a [routerLink]="['/activities/ieee-student-chapter']">IEEE Students Chapter</a>
                                </li>
                                <li><a [routerLink]="['/activities/counselling-cell']">Counselling Cell</a></li>
                                <li>
                                    <a [routerLink]="['/rd-cell']">R & D</a>
                                </li>
                                <li><a [routerLink]="['/activities/youth-festival']">Youth Festival</a></li>
                                <li><a [routerLink]="['/activities/nss']">NSS</a></li>
                                <li><a [routerLink]="['/activities/media-watch']">Media News</a></li>
                                <li><a href="http://prpotepatilengg.logixspire.com/wp-content/uploads/2020/12/Report_Graduation_Day-Ceremony_Batch21-Updated.pdf"
                                        target="_blank">Graduation Day Ceremony 2022</a></li>
                                <li><a
                                        href="https://www.prpotepatilengg.ac.in/IQAC/Other_Files/PRPCEM-Annual_Report_of_the_Institution-2021-22-Final.pdf">Annual
                                        Report 2021-2022</a></li>
                                <li><a
                                        href="https://prpotepatilengg.ac.in/wp-content/uploads/2020/12/Revised-Portfolio.pdf">Institution
                                        Level Portfolios 2020-21</a></li>
                                <li><a [routerLink]="['/activities/women-griev']">Women's Grievance Cell</a></li>
                            </ul>
                        </li>
                        <li>
                            <a [routerLink]="['/other/iqac/1']">IQAC</a>
                        </li>
                        <li>
                            <a [routerLink]="['/naac-ssr']">NAAC</a>
                        </li>
                        <li>
                            <a href="javascript:void(0);">NIRF<i class="fa fa-chevron-down"></i></a>
                            <ul class="sub-menu tab-content">
                                <li><a href="https://prpotepatilengg.ac.in/wp-content/uploads/2020/12/NIRF_Engineering.pdf"
                                        target="_blank">NIRF Pharmacy</a></li>
                                <li><a
                                        href="https://prpotepatilengg.ac.in/wp-content/uploads/2020/12/NIRF_Management.pdf">NIRF
                                        Management</a></li>
                            </ul>
                        </li>
                        <li>
                            <a href="javascript:void(0);">Alumni<i class="fa fa-chevron-down"></i></a>
                            <ul class="sub-menu tab-content">
                                <li><a [routerLink]="['/alumni-association']">Alumni Association</a></li>
                                <div style="margin: 10px 0 0; font-weight: 600; font-size: 12px; margin-left: 1rem; color: black; padding: 10px;background-color: rgb(224, 224, 224);">
                                    Alumni Information
                                </div>
                                <li style="padding-left: 1rem;" class=""><a [routerLink]="['/alumni-dpharm']">D-Pharm</a></li>
                                <li style="padding-left: 1rem;"><a [routerLink]="['/alumni-bpharm']">B-Pharm</a></li>
                                <li><a [routerLink]="['/outstanding-alumni']">Outstanding Alumni</a></li>
                                <li><a [routerLink]="['/alumni-meet']">Alumni Meet</a></li>
                            </ul>
                        </li>
                        <li>
                            <a href="javascript:void(0);">Facilities<i class="fa fa-chevron-down"></i></a>
                            <ul class="sub-menu tab-content">
                                <li><a [routerLink]="['/campus/classroom']">Classroom</a></li>
                                <li><a [routerLink]="['/campus/library']">Library</a></li>
                                <li><a [routerLink]="['/campus/seminar-hall']">Seminar Hall & Yoga Center</a></li>
                                <li><a [routerLink]="['/campus/sports']">Sports & Medical Room</a></li>
                                <li><a [routerLink]="['/campus/transportation']">Transportation</a></li>
                                <li><a [routerLink]="['/campus/canteen']">Canteen</a></li>
                                <li><a [routerLink]="['/campus/auditorium']">Auditorium</a></li>
                                <li><a [routerLink]="['/campus/bank-atm']">Bank ATM</a></li>
                            </ul>
                        </li>

                        <li>
                            <a [routerLink]="['/events']">Events</a>
                        </li>
                        
                        <li>
                            <a href="javascript:void(0);">Sports<i class="fa fa-chevron-down"></i></a>
                            <ul class="sub-menu tab-content">
                                <li><a [routerLink]="['/sports-participation']">Participation</a></li>
                                <li><a [routerLink]="['/sports-awards']">Awards & Accolades</a></li>
                             </ul>
                        </li>
                        <li>
                            <a href="https://pharmacy.prpotepatilengineering.org/Engg_Default.aspx" target="_blank">Know Your Fees</a>
                        </li>
                        
                        <li>
                            <a [routerLink]="['/bulletin']">e-Bulletin</a>
                        </li>
                        <li>
                            <a [routerLink]="['/contact-us']">Contact</a>
                        </li>
                    </ul>
                    <div class="dlab-social-icon">
                        <ul>
                            <li><a class="site-button facebook sharp-sm outline fa fa-facebook"
                                    href="javascript:void(0);"></a></li>
                            <li><a class="site-button twitter sharp-sm outline fa fa-twitter"
                                    href="javascript:void(0);"></a></li>
                            <li><a class="site-button linkedin sharp-sm outline fa fa-linkedin"
                                    href="javascript:void(0);"></a></li>
                            <li><a class="site-button instagram sharp-sm outline fa fa-instagram"
                                    href="javascript:void(0);"></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- main header END -->
</header>