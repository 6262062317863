<div class="section-full bg-gray content-inner about-carousel-ser">
	<div class="container">

		<div class="section-head m-b20">
            <h2 class="title" style="color: #F24C3D;font-family: Rockwell !important;font-weight: 500;">Virtual Campus Tour</h2>
            <div class="dlab-separator bg-primary" style="background-color: #d6000059 !important;"></div>
        </div>

		<div class="row" style="display: flex;justify-content: center;margin-top: 2rem;">
			<div class="col-md-6" >
				<iframe height="350" style="width: 100%;" src="https://www.youtube.com/embed/cq-ZSyxJi-s" border="0">
				</iframe>
			</div>
		</div>

		<div class="section-head m-b20" style="margin-top: 2rem;">
            <h2 class="title" style="color: #F24C3D;font-family: Rockwell !important;font-weight: 500;">Courses Offered</h2>
            <div class="dlab-separator bg-primary" style="background-color: #d6000059 !important;"></div>
        </div>
		<div class="row">
			<div class="col-md-4 col-lg-4 col-sm-4">
				<div class="dlab-box courses-bx">
					<div class="dlab-media">
						<img src="../../../assets/images/our-services/coaching/DPHARM.png" alt="">
					</div>
					<div class="dlab-info">
						<h4 style="font-size: 18px;" class="dlab-title"><a [routerLink]="['/dpharm']">D. Pharm</a></h4>
						<!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting.</p> -->
					</div>
				</div>
			</div>
			<div class="col-md-4 col-lg-4 col-sm-4">
				<div class="dlab-box courses-bx">
					<div class="dlab-media">
						<img src="../../../assets/images/our-services/coaching/B-PHARM.png" alt="">
					</div>
					<div class="dlab-info">
						<h4 style="font-size: 18px;" class="dlab-title"><a [routerLink]="['/bpharm']">B. Pharm</a></h4>
						<!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting.</p> -->
					</div>
				</div>
			</div>
			<div class="col-md-4 col-lg-4 col-sm-4">
				<div class="dlab-box courses-bx">
					<div class="dlab-media">
						<img src="../../../assets/images/our-services/coaching/mpharm.png" alt="">
					</div>
					<div class="dlab-info">
						<h4 style="font-size: 18px;" class="dlab-title"><a [routerLink]="['/dpharm']">M. Pharm</a></h4>
						<!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting.</p> -->
					</div>
				</div>
			</div>
		</div>
	</div>
</div>