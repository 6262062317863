import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-naac-ssr',
  templateUrl: './naac-ssr.component.html',
  styleUrls: ['./naac-ssr.component.css']
})
export class NaacSsrComponent implements OnInit {

  banner: any = {
    pagetitle: "NAAC",
    bg_image: "assets/images/banner/bnr3.jpg",
    title: "NAAC - DATA VERIFICATION AND VALIDATION",
  }
  
  constructor() { }

  ngOnInit(): void {
  }

}
