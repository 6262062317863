<div class="content-block">
    <app-header-banner [data]="banner"></app-header-banner>
    <div class="container" style="margin-top: 10px">
       
        <main id="main">
            <section class="fadeInUp section-bg wow" style="visibility: visible; animation-name: fadeInUp;">
                <div class="container text-center">
                    <header class="section-header">
                        <h3>Alumni Information B.Pharm</h3>
                    </header>
                    <div class="row">
                        <div class="col-md-12 mt-5">
                            <a target="_blank" href="http://www.prpotepatilpharma.ac.in/alumni_information/B.Pharm/B Pharm Batch 2020-21.pdf" style="color: #f2622d;">
                                <img src="../../../../assets/images/pdf_logo.png" style="height: 150px;"><br>
                                <br>
                                <h5>B.Pharm Batch 2020-21</h5>
                            </a>
                        </div>


                    </div>
                </div>
            </section>
        </main>
        
    </div>
</div>