import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css']
})
export class EventsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  
  banner: any = {
    pagetitle: "Events",
    bg_image: "assets/images/banner/bnr3.jpg",
    title: "Events",
  }
}
