

<mat-dialog-content class="mat-typography">
    <div class="row" style="display: flex; justify-content: center;align-items: center;margin-top: 1rem;">
        <!-- <img src="assets/images/addmission_banner_2024.jpeg"> -->
        
        <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img class="d-block w-100" src="../../../assets/images/home-banner/World pharmacist day 2023.jpeg" alt="First slide">
              </div>
              <div class="carousel-item">
                <img class="d-block w-100" src="../../../assets/images/home-banner/1.jpeg" alt="Second slide">
              </div>
              <div class="carousel-item">
                <img class="d-block w-100" src="../../../assets/images/home-banner/2.jpeg" alt="Third slide">
              </div>
              <div class="carousel-item">
                <img class="d-block w-100" src="../../../assets/images/home-banner/3.jpeg" alt="Forth slide">
              </div>
              <div class="carousel-item">
                <img class="d-block w-100" src="../../../assets/images/home-banner/4.jpeg" alt="Fifth slide">
              </div>
            </div>
            <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </a>
          </div>
    </div>
</mat-dialog-content>