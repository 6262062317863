<div class="section-full content-inner bg-white">
    <div class="container">
        <div class="section-head m-b20">
            <h2 class="title" style="color: #F24C3D;font-family: Rockwell !important;font-weight: 500;">Our Vision /
                Mission </h2>
            <div class="dlab-separator bg-primary" style="background-color: #d6000059 !important;"></div>
        </div>
        <div class="vision-mission" style="margin-bottom: 2rem;">
            <div
                style="border: 1px solid rgba(128, 128, 128, 0.24);padding: 0 !important;background: #fff;box-shadow: 0 10px 15px 0 rgba(0, 0, 0, .05) !important;border-radius: 0 70px 0 70px;margin-right: 5px;">
                <div
                    style="color: #fff;padding: 10px;background-color: #0e2954;font-size: 17px;font-weight: 600;text-transform: uppercase;text-align: center;    border-radius: 0 70px 0 0;">
                    Our Vision
                </div>
                <div style="color: #000;padding: 10px 20px;font-size: 14px;font-weight: 600;line-height: 1.7rem;text-align: justify;">
                    To provide quality education and training to enable students to face emerging challenges of the
                    globalized pharmaceutical technology, competitiveness and entrepreneurship.
                </div>
            </div>

            <div
                style="border: 1px solid rgba(128, 128, 128, 0.24);padding: 0 !important;background: #fff;box-shadow: 0 10px 15px 0 rgba(0, 0, 0, .05) !important;border-radius: 0 70px 0 70px;margin-right: 5px;">
                <div
                    style="color: #fff;padding: 10px;background-color: #0e2954;font-size: 17px;font-weight: 600;text-transform: uppercase;text-align: center;    border-radius: 0 70px 0 0;">
                    Our Mission
                </div>
                <div style="color: #000;padding: 10px 20px;font-size: 14px;font-weight: 600;">
                    <ul style="margin-left: 1rem;line-height: 1.7rem;">
                        <li>To obtain in depth knowledge and expertise in the field of Pharmaceutical Sciences for
                            providing the need of pharmacy profession and society at large.</li>
                        <li>To provide research oriented educational environment that encourages individuals to make
                            positive lifelong contributions to global health</li>
                    </ul>
                </div>
            </div>

            <div style="border: 1px solid rgba(128, 128, 128, 0.24);padding: 0 !important;background: #fff;
    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, .05) !important;border-radius: 0 70px 0 70px;margin-right: 5px;">
                <div
                    style="color: #fff;padding: 10px;background-color: #0e2954;font-size: 17px;font-weight: 600;text-transform: uppercase;text-align: center;    border-radius: 0 70px 0 0;">
                    PROGRAMM EDUCATIONAL OBJECTIVES (PEO'S)
                </div>
                <div style="color: #000;padding: 10px 20px;font-size: 14px;font-weight: 600;">
                    <ul style="margin-left: 1rem;line-height: 1.7rem;">
                        <li>
                            Comprehension- Graduates should acquire in-depth knowledge of pharmaceutical sciences and interdisciplinary technical skills.
                        </li>
                        <li>
                            Adequacy- Graduates should upgrade their adopted skill for the betterment of pharmaceutical practices to execute quality work.
                        </li>
                        <li>
                            Scope and Proficient- Graduates should promote themselves for higher studies to contribute to the development of Pharma sector and relevant research.
                        </li>
                        <li>
                            Appraisal- Graduates should deliver their duties and responsibilities within the frame of ethics to accomplish the need of profession and society at large.
                        </li>                    
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>