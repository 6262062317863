<div class="content-block">
    <app-header-banner [data]="banner"></app-header-banner>
    <div class="container" style="margin-top: 10px;background:#fff;border-radius:15px;padding:40px">
        <main id="main">
            <section class="fadeInUp section-bg wow" style="visibility: visible; animation-name: fadeInUp;">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="accordion" id="accordionExample">
                                <div class="card">
                                    <div class="card-header" id="headingFour">
                                        <h2 class="mb-0"><button type="button" class="btn btn-link"
                                                style="color:#000;font-weight:700;float:left" data-toggle="collapse"
                                                data-target="#collapseFour" aria-expanded="true"
                                                aria-controls="collapseFour">Bulletin 2022-23</button></h2>
                                    </div>
                                    <div class="collapse" id="collapseFour" aria-labelledby="headingFour"
                                        data-parent="#accordionExample">
                                        <div class="card-body">
                                            <div class="container text-center">
                                                <div class="row">

                                                    <!-- <div class="col-md-4">
                                                        <img class="card-img-top" src="http://www.prpotepatilpharma.ac.in/img/bulletin/2021/07 June 2022.jpg"><br>7th June 2022
                                                    </div> -->

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingOne">
                                        <h2 class="mb-0"><button type="button" class="btn btn-link"
                                                style="color:#000;font-weight:700;float:left" data-toggle="collapse"
                                                data-target="#collapseOne" aria-expanded="true"
                                                aria-controls="collapseOne">Bulletin 2021-22</button></h2>
                                    </div>
                                    <div class="collapse" id="collapseOne" aria-labelledby="headingOne"
                                        data-parent="#accordionExample">
                                        <div class="card-body">
                                            <div class="container text-center">
                                                <div class="row">

                                                    <div class="col-md-4">
                                                        <img class="card-img-top"
                                                            src="http://www.prpotepatilpharma.ac.in/img/bulletin/2021/07 June 2022.jpg"><br>7th June 2022
                                                    </div>
                                                    <div class="col-md-4">
                                                        <img class="card-img-top"
                                                            src="http://www.prpotepatilpharma.ac.in/img/bulletin/2021/7th%20May%202022.jpg"><br>7th May 2022
                                                    </div>
                                                    <div class="col-md-4">
                                                        <img class="card-img-top"
                                                            src="http://www.prpotepatilpharma.ac.in/img/bulletin/2021/07th April 2022.jpg"><br>7th April
                                                        2022
                                                    </div>
                                                    <div class="col-md-4">
                                                        <img class="card-img-top"
                                                            src="http://www.prpotepatilpharma.ac.in/img/bulletin/2021/5th march 2022.jpg"><br>5th March
                                                        2022
                                                    </div>
                                                    <div class="col-md-4">
                                                        <img class="card-img-top"
                                                            src="http://www.prpotepatilpharma.ac.in/img/bulletin/2021/5th Feb 2022.jpg"><br>5th Feb. 2022
                                                    </div>
                                                    <div class="col-md-4">
                                                        <img class="card-img-top"
                                                            src="http://www.prpotepatilpharma.ac.in/img/bulletin/2021/7th jan 22.jpg"><br>7th Jan. 2022
                                                    </div>
                                                    <div class="col-md-4">
                                                        <img class="card-img-top"
                                                            src="http://www.prpotepatilpharma.ac.in/img/bulletin/2021/7th Dec 2021.jpeg"><br>7th Dec. 2021
                                                    </div>
                                                    <div class="col-md-4">
                                                        <img class="card-img-top"
                                                            src="http://www.prpotepatilpharma.ac.in/img/bulletin/2021/12th nov 21.jpg"><br>12th Nov. 2021
                                                    </div>
                                                    <div class="col-md-4">
                                                        <img class="card-img-top"
                                                            src="http://www.prpotepatilpharma.ac.in/img/bulletin/2021/5th oct 21.jpeg"><br>5th Oct. 2021
                                                    </div>
                                                    <div class="col-md-4">
                                                        <img class="card-img-top"
                                                            src="http://www.prpotepatilpharma.ac.in/img/bulletin/2021/7th sept 21.jpg"><br>7th Sept. 2021
                                                    </div>
                                                    <div class="col-md-4">
                                                        <img class="card-img-top"
                                                            src="http://www.prpotepatilpharma.ac.in/img/bulletin/2021/6th Aug 2021.jpeg"><br>6th August
                                                        2021
                                                    </div>
                                                    <div class="col-md-4">
                                                        <img class="card-img-top"
                                                            src="http://www.prpotepatilpharma.ac.in/img/bulletin/2021/5th July 21.jpg"><br>5th July 2021
                                                    </div>
                                                    <div class="col-md-4">
                                                        <img class="card-img-top"
                                                            src="http://www.prpotepatilpharma.ac.in/img/bulletin/2021/6th June 21.jpg"><br>6th June 2021
                                                    </div>
                                                    <div class="col-md-4">
                                                        <img class="card-img-top"
                                                            src="http://www.prpotepatilpharma.ac.in/img/bulletin/2021/June 21.jpg"><br>June 2021
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingTwo">
                                        <h2 class="mb-0"><button type="button" class="btn btn-link"
                                                style="color:#000;font-weight:700;float:left" data-toggle="collapse"
                                                data-target="#collapseTwo" aria-expanded="true"
                                                aria-controls="collapseTwo">Bulletin 2020-21</button></h2>
                                    </div>
                                    <div class="collapse" id="collapseTwo" aria-labelledby="headingTwo"
                                        data-parent="#accordionExample">
                                        <div class="card-body">
                                            <div class="container text-center">
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <img class="card-img-top"
                                                            src="http://www.prpotepatilpharma.ac.in/img/bulletin/2021/8th April 21.jpg"><br>8th April 2021
                                                    </div>
                                                    <div class="col-md-4">
                                                        <img class="card-img-top"
                                                            src="http://www.prpotepatilpharma.ac.in/img/bulletin/2021/8th march 2021.jpg"><br>8th March
                                                        2021
                                                    </div>
                                                    <div class="col-md-4">
                                                        <img class="card-img-top"
                                                            src="http://www.prpotepatilpharma.ac.in/img/bulletin/2021/8th Feb 21.jpg"><br>8th Feb. 2021
                                                    </div>
                                                    <div class="col-md-4">
                                                        <img class="card-img-top"
                                                            src="http://www.prpotepatilpharma.ac.in/img/bulletin/2021/18th jan 2021.jpg"><br>18th Jan. 2021
                                                    </div>
                                                    <div class="col-md-4">
                                                        <img class="card-img-top"
                                                            src="http://www.prpotepatilpharma.ac.in/img/bulletin/2020/26th Dec 2020.jpg"><br>26th Dec. 2020
                                                    </div>
                                                    <div class="col-md-4">
                                                        <img class="card-img-top"
                                                            src="http://www.prpotepatilpharma.ac.in/img/bulletin/2020/8th Dec 2020.jpg"><br>8th Dec. 2020
                                                    </div>
                                                    <div class="col-md-4">
                                                        <img class="card-img-top"
                                                            src="http://www.prpotepatilpharma.ac.in/img/bulletin/2020/15th Oct 2020.jpg"><br>15th Oct. 2020
                                                    </div>
                                                    <div class="col-md-4">
                                                        <img class="card-img-top"
                                                            src="http://www.prpotepatilpharma.ac.in/img/bulletin/2020/25th Sep 2020.jpg"><br>25th Sept.
                                                        2020
                                                    </div>
                                                    <div class="col-md-4">
                                                        <img class="card-img-top"
                                                            src="http://www.prpotepatilpharma.ac.in/img/bulletin/2020/29th Aug 2020.jpg"><br>29th Aug. 2020
                                                    </div>
                                                    <div class="col-md-4">
                                                        <img class="card-img-top"
                                                            src="http://www.prpotepatilpharma.ac.in/img/bulletin/2020/23rd Aug 2020.jpg"><br>23rd Aug. 2020
                                                    </div>
                                                    <div class="col-md-4">
                                                        <img class="card-img-top"
                                                            src="http://www.prpotepatilpharma.ac.in/img/bulletin/2020/6th july 2020.jpeg"><br>6th July 2020
                                                    </div>
                                                    <div class="col-md-4">
                                                        <img class="card-img-top"
                                                            src="http://www.prpotepatilpharma.ac.in/img/bulletin/2020/21st june 2020.jpg"><br>21st June
                                                        2020
                                                    </div>
                                                    <div class="col-md-4">
                                                        <img class="card-img-top"
                                                            src="http://www.prpotepatilpharma.ac.in/img/bulletin/2020/2nd june 2020.jpg"><br>2nd June 2020
                                                    </div>
                                                    <div class="col-md-4">
                                                        <img class="card-img-top"
                                                            src="http://www.prpotepatilpharma.ac.in/img/bulletin/2020/2nd june 2020 1.jpg"><br>2nd June
                                                        2020
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingThree">
                                        <h2 class="mb-0"><button type="button" class="btn btn-link collapsed"
                                                style="color:#000;font-weight:700;float:left" data-toggle="collapse"
                                                data-target="#collapseThree" aria-expanded="false"
                                                aria-controls="collapseThree">Bulletin 2019-20</button></h2>
                                    </div>
                                    <div class="collapse" id="collapseThree" aria-labelledby="headingThree"
                                        data-parent="#accordionExample">
                                        <div class="card-body">
                                            <div class="container text-center">
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <img class="card-img-top"
                                                            src="http://www.prpotepatilpharma.ac.in/img/bulletin/2020/20th Feb 2020.jpg"><br>20th Feb. 2020
                                                    </div>
                                                    <div class="col-md-4">
                                                        <img class="card-img-top"
                                                            src="http://www.prpotepatilpharma.ac.in/img/bulletin/2020/26th Jan 2020.jpg"><br>26th Jan. 2020
                                                    </div>
                                                    <div class="col-md-4">
                                                        <img class="card-img-top"
                                                            src="http://www.prpotepatilpharma.ac.in/img/bulletin/2019/29th Dec 2019.jpg"><br>29th Dec 2019
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section><br><br><br>

        </main>
    </div>
</div>