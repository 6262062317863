<footer class="site-footer text-uppercase">
    <div class="footer-top" style="background-image:url(images/pattern/pt15.png);">
        <div class="container">
            <div class="row">
                <div class="col-md-5 col-12 col-xl-5 col-lg-5 col-sm-6 footer-col-4">
                    <div class="widget widget_services border-0">
                        <div style="display: flex;justify-content: center;">
                            <img src="../../assets/images/pote_pharmacy_logo.png" style="width: 100px;" alt="">
                        </div>
                        <div style="text-align: center;line-height: 1.7rem;margin-top: 1rem;">
                            <p>
                                <span style="color: white;">
                                    P. R. Pote Patil Education & Welfare Trust's
                                </span> <br>
                                <span style="color: lightcoral;">
                                    P. R. Pote Patil College of Pharmacy, Amravati.
                                </span> <br>
                                <span style="color: bisque;">
                                    Recognized by AICTE & PCI New Delhi, DTE and Affiliated to SGBAU & MSBTE
                                </span>
                            </p>
                        </div>
                    </div>
                </div>

                <div class="col-md-4 col-12 col-xl-4 col-lg-4 col-sm-6 footer-col-4">
                    <div class="widget widget_services border-0">
                        <h5 class="m-b30 text-white">CONTACT US</h5>
                        <hr>
                        <div style="line-height: 2rem;">
                            <p style="text-transform: capitalize !important;">
                                Gajanan Township Road, <br>
                                Pote Estate, Amravati 444602, <br>
                                Maharashtra, India. <br>
                                Phone : 0721-2970465/ +91 9422264498 <br>
                                Email : prppharmacycollege@gmail.com <br>
                            </p>
                        </div>
                    </div>
                </div>

                <div class="col-md-12 col-xl-3 col-lg-3 col-sm-6 footer-col-3 ">
                    <div class="widget widget_services border-0">
                        <h5 class="m-b30 text-white">Useful Link</h5>
                        <hr>
                        <ul>
                            <li><i class="ion-ios-arrow-right"></i> <a target="_blank"
                                    href="https://www.aicte-india.org">AICTE</a></li>
                            <li><i class="ion-ios-arrow-right"></i> <a target="_blank"
                                    href="http://www.pci.nic.in">PCI</a></li>
                            <li><i class="ion-ios-arrow-right"></i> <a target="_blank"
                                    href="http://www.dtemaharashtra.gov.in">DTE</a></li>
                            <li><i class="ion-ios-arrow-right"></i> <a target="_blank"
                                    href="https://www.sgbau.ac.in">SGBAU</a></li>
                            <li><i class="ion-ios-arrow-right"></i> <a target="_blank"
                                    href="https://msbte.org.in">MSBTE</a></li>
                            <li><i class="ion-ios-arrow-right"></i> <a target="_blank"
                                    href="http://www.naac.gov.in/">NAAC</a></li>
                            <li><i class="ion-ios-arrow-right"></i> <a target="_blank"
                                    href="https://www.nbaind.org">NBA</a></li>
                            <li><i class="ion-ios-arrow-right"></i> <a target="_blank"
                                    href="https://onlinecourses.nptel.ac.in/">Swayam Nptel</a></li>
                            <li><i class="ion-ios-arrow-right"></i> <a target="_blank"
                                    href="https://mahadbt.maharashtra.gov.in/">State Government Scholership</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- footer bottom part -->
    <div class="footer-bottom">
        <div class="container">
            <div class="row">
                <div class="col-md-4 col-sm-4 text-left "> <span>Copyright © 2023 PRPP College of Pharmacy</span> </div>
                <div class="col-md-4 col-sm-4 text-right ">
                    <span>Made with <span style="color: red;">&hearts;</span> in India</span>
                </div>
                <div class="col-md-4 col-sm-4 text-right ">
                    <span>design & developed by <a href="http://logixspire.com" target="_blank">LogixSpire</a></span>
                </div>
            </div>
        </div>
    </div>
</footer>