<style>
    ul {
        margin-left: 2rem;
    }
</style>

<div class="row" style="display: flex;justify-content: center;">
    <h3 style="text-align: center;">
        {{activity_data.title}}
    </h3>
</div>
<div class="row" style="display: flex;justify-content: center;">
    {{activity_data.sub_title_posted_by}} &nbsp;|&nbsp; {{activity_data.sub_title_date | date}} &nbsp;|&nbsp;
    {{activity_data.sub_title_year}}
</div>
<br>
<div class="row" style="display: flex;justify-content: center;">
    <img src="{{activity_data.banner}}" style="width: 50%;">
</div>
<br>
<div [innerHTML]="activity_data.content" style="text-align: justify;"></div>




<hr>
<div class="row" style="padding: 10px 0; margin: 10px; background: #8080800f;">
    <div class="col-md-3" *ngFor="let obj of activity_data.images_data" style="margin-top: 10px;">
        <img [src]="obj">
    </div>
    <div class="col-md-3" *ngFor="let obj of activity_data.pdf_data" style="margin-top: 10px;">
        <a target="_blank" [href]="obj">
            <img style="width: 7rem;" src="../../../assets/images/pdf_logo.jpg">
        </a>
    </div>
</div>