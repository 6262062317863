<div class="content-block">
    <app-header-banner [data]="banner"></app-header-banner>
    <div class="container" style="margin-top: 10px">
        <div>
            <div class="section-header" style="margin-top: 2rem;">
                <h3>ADMISSION</h3>
            </div>
            <div class="body-style-new">
                <h1></h1>
                <ul style="text-align: justify;line-height: 2rem;">
                    <li>The Admission that may be granted to the students will be provisional subject to approval of the
                        Directorate of Technical Education, M.S. Mumbai.</li>
                    <li>If any of the statement made in application form or any information supplied by the candidate in
                        connection with his/ her admission is, at any time found to be false or incorrect, his/her
                        admission will be cancelled, fees forfeited
                        and he/she may be expelled from the institute by the Principal and prosecuted by Government, if
                        deemed necessary.</li>
                    <li>An appeal against the order of expulsion, however, may be preferred within eight days from the
                        date of letter of expulsion to the Director of Technical Education whose decision in such cases
                        will be final.</li>
                    <li>The Backward Class students shall produce all caste related certificate from competent
                        authority, wherever necessary.</li>
                </ul>
            </div>
        </div>

        <div>
            <div class="section-header" style="margin-top: 2rem;">
                <h3>FEES AND CONCESSIONS</h3>
            </div>
            <div class="body-style-new">
                <ul style="text-align: justify;line-height: 2rem;">
                    <li><b>B. Pharm-</b> Rs. 85000/-</li>
                    <li><b>D. Pharm-</b> Rs. 65000/-</li>
                    <li><b>Tuition fees-</b> As per ShikshanShulkaSamiti norms</li>
                    <li><b>Development fees -</b> As per ShikshanShulkaSamiti norms</li>
                    <li><b>Other fees-</b> As per ShikshanShulkaSamiti norms</li>
                </ul>
            </div>
        </div>

        <div>
            <div class="section-header" style="margin-top: 2rem;">
                <h3>DISCIPLINE</h3>
            </div>
            <div class="body-style-new">
                <h1></h1>
                <ul style="text-align: justify;line-height: 2rem;">
                    <li>Student must abide by the rules and regulations made by the institute/ SGB Amravati University,
                        Board of Technical Examination/Directorate of Technical Education/Pharmacy Council of India from
                        time to time.The students should
                        carry an identity card with them regularly and it should be produced if demanded by authority of
                        the college.</li>
                    <li>Ragging in any form within or outside the college and hostels is totally banned. When brought to
                        the notice, defaulter will be summarily expelled from the college.</li>
                    <li>Smoking or chewing gum, gutkha, and pan in the premises is strictly prohibited.</li>
                    <li>The use of electronic communication device like mobile phone, pager are strictly prohibited in
                        the college.</li>
                    <li>The students should not involve in any such activity which may cause damage to the college
                        property. It will be viewed seriously and is likely to result in the recovery of the cost of
                        damage.</li>
                    <li>The above rules of discipline are subjected to changes or modifications. These modifications can
                        be done whenever the Principal deems it fit &amp; proper. The decision of the Principal shall be
                        final and binding on the students.</li>
                    <li>Notice board and the institutional website are the only authorized media of communication with
                        the students and others.</li>
                    <li>The students should read the notice displayed on the notice board regularly and the college will
                        not be responsible for the loss of any advantage due to the negligence of reading the notice by
                        the students.</li>
                </ul>
            </div>
        </div>

        <div>
            <div class="section-header" style="margin-top: 2rem;">
                <h3>EXAMINATION</h3>
            </div>
            <div class="body-style-new">
                <h1></h1>
                <ul style="text-align: justify;line-height: 2rem;">
                    <li>No student will be allowed to appear for the annual Examination unless:
                        <ul class="a" style="margin-left: 2rem;">
                            <li>He/ she have paid all dues.</li>
                            <li>He/she have completed all the practical record book duly checked and certified by the
                                concerned;</li>
                            <li>He/ she have requisite percentage of attendance.</li>
                            <li>He/she has submitted the clearance certificate</li>
                        </ul>
                    </li><br>
                    <li>The attendance for all sessional of theory &amp; practicals conducted by the college is
                        compulsory.</li>
                </ul>
            </div>
        </div>
    </div>
</div>