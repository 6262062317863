<div class="content-block">
    <app-header-banner [data]="banner"></app-header-banner>
    <div class="container" style="margin-top: 10px">
        <main id="main">
            <section class="fadeInUp section-bg wow" style="visibility: visible; animation-name: fadeInUp;">
                <div class="container">
                    <header class="section-header">
                        <h3>Anti Ragging/ Harassment Committee</h3>
                    </header>
                    <p style="text-align:justify">As per AICTE Notification F. No. 37.3/ Legal/ AICTE/2009 6 (A) for Prevention and prohibition of Ragging/Harassment in technical institute, the institute has constituted following Anti Ragging/Harassment Committee. It shall be the
                        duty of Anti Ragging/Harassment committee to ensure compliance with the provision of these regulations as well as the provisions of any law for the time being in force concerning ragging and also to monitor Anti Ragging squad in
                        prevention of ragging in institute.<br>The members of the committee are as follows:</p><br>
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <th scope="col">Sr. No.</th>
                                <th scope="col">Name</th>
                                <th scope="col">Designation</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">1</th>
                                <td>Dr. Dipti Bipinchandra Ruikar (Deshmukh)</td>
                                <td>Chairman</td>
                            </tr>
                            <tr>
                                <th scope="row">2</th>
                                <td>Mr. Pankaj H. Chaudhary</td>
                                <td>Co-ordinator</td>
                            </tr>
                            <tr>
                                <th scope="row">3</th>
                                <td>Mr. Prashant J. Burange</td>
                                <td>Co-ordinator</td>
                            </tr>
                            <tr>
                                <th scope="row">4</th>
                                <td>Shri. Vilas Marathe</td>
                                <td>Member – Media</td>
                            </tr>
                            <tr>
                                <th scope="row">5</th>
                                <td>Adv. Pravin M. Mohod</td>
                                <td>NGO in Youth Activity</td>
                            </tr>
                            <tr>
                                <th scope="row">6</th>
                                <td>Parent</td>
                                <td>Member</td>
                            </tr>
                            <tr>
                                <th scope="row">7</th>
                                <td>Class Representative</td>
                                <td>Fresher Student (B. Pharm)</td>
                            </tr>
                            <tr>
                                <th scope="row">8</th>
                                <td>Class Representative</td>
                                <td>Senior Student (B. Pharm)</td>
                            </tr>
                            <tr>
                                <th scope="row">9</th>
                                <td>Class Representative</td>
                                <td>Fresher Student (D. Pharm)</td>
                            </tr>
                            <tr>
                                <th scope="row">10</th>
                                <td>Class Representative</td>
                                <td>Senior Student (D. Pharm)</td>
                            </tr>
                            <tr>
                                <th scope="row">11</th>
                                <td>Mr. Sandeep M. Narad</td>
                                <td>Office Superintendent</td>
                            </tr>
                            <tr>
                                <th scope="row">12</th>
                                <td>Mr. Krunal G. Tiwari</td>
                                <td>Representative of non teaching</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section><br><br><br>

        </main>
    </div>
</div>