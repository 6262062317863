<div class="content-block">
    <app-header-banner [data]="banner"></app-header-banner>
    <div class="container" style="margin-top: 10px; padding: 2rem;">
        <div class="accordion" id="accordionExample">
            <div class="card">
                <div class="card-header" id="headingOne">
                    <h2 class="mb-0">
                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse"
                            data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne"
                            style="color: #000;font-weight: bold;float: left;">
                            AICTE (All India Council for Technical Education)

                        </button>
                    </h2>
                </div>

                <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                    <div class="card-body">
                        <div class="container text-center">
                            <div class="row">
                                <div class="col-md-4">
                                    <a target="_blank"
                                        href="../../assets/pdf/approval/EOA_Report_2019-20 (Pharmacy).pdf"
                                        style="color: #f2622d;"><img src="../../assets/images/pdf_logo.png"
                                            style="height: 50px;"><br>
                                        EOA_Report_2019-20 (Pharmacy)</a>
                                </div>
                                <div class="col-md-4">
                                    <a target="_blank"
                                        href="../../assets/pdf/approval/EOA Report_2018-19 (Pharmacy).pdf"
                                        style="color: #f2622d;"><img src="../../assets/images/pdf_logo.png"
                                            style="height: 50px;"><br>
                                        EOA Report_2018-19 (Pharmacy)</a>
                                </div>
                                <div class="col-md-4">
                                    <a target="_blank"
                                        href="../../assets/pdf/approval/EOA Report_2017-18 (Pharmacy).pdf"
                                        style="color: #f2622d;"><img src="../../assets/images/pdf_logo.png"
                                            style="height: 50px;"><br>
                                        EOA Report_2017-18 (Pharmacy)</a>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" id="headingTwo">
                    <h2 class="mb-0">
                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse"
                            data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"
                            style="color: #000;font-weight: bold;float: left;">
                            AISHE (All India Survey on Higher Education)
                        </button>
                    </h2>
                </div>
                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                    <div class="card-body">
                        <div class="container text-center">
                            <div class="row">
                                <div class="col-md-4">
                                    <a target="_blank" href="../../assets/pdf/approval/C-58227-2019-20.pdf"
                                        style="color: #f2622d;"><img src="../../assets/images/pdf_logo.png"
                                            style="height: 50px;"><br>
                                        C-58227-2019-20</a>
                                </div>
                                <div class="col-md-4">
                                    <a target="_blank" href="../../assets/pdf/approval/C-58227-2018-19.pdf"
                                        style="color: #f2622d;"><img src="../../assets/images/pdf_logo.png"
                                            style="height: 50px;"><br>
                                        C-58227-2018-19</a>
                                </div>
                                <div class="col-md-4">
                                    <a target="_blank" href="../../assets/pdf/approval/C-58227- 2017-18.pdf"
                                        style="color: #f2622d;"><img src="../../assets/images/pdf_logo.png"
                                            style="height: 50px;"><br>
                                        C-58227- 2017-18</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" id="headingThree">
                    <h2 class="mb-0">
                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse"
                            data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree"
                            style="color: #000;font-weight: bold;float: left;">
                            PCI (Pharmacy Council of India)
                        </button>
                    </h2>
                </div>
                <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                    <div class="card-body">
                        <div class="container text-center">
                            <div class="row">
                                <div class="col-md-3">
                                    <a target="_blank" href="../../assets/pdf/approval/PCI Approval 2021-22.pdf"
                                        style="color: #f2622d;"><img src="../../assets/images/pdf_logo.png"
                                            style="height: 50px;"><br>
                                        Approval Letter 2021-22</a>
                                </div>
                                <div class="col-md-3">
                                    <a target="_blank" href="../../assets/pdf/approval/PCI(2020-2021).pdf"
                                        style="color: #f2622d;"><img src="../../assets/images/pdf_logo.png"
                                            style="height: 50px;"><br>
                                        Approval Letter 2020-21</a>
                                </div>
                                <div class="col-md-3">
                                    <a target="_blank" href="../../assets/pdf/approval/Approval Letter 2019-20.pdf"
                                        style="color: #f2622d;"><img src="../../assets/images/pdf_logo.png"
                                            style="height: 50px;"><br>
                                        Approval Letter 2019-20</a>
                                </div>
                                <div class="col-md-3">
                                    <a target="_blank"
                                        href="../../assets/pdf/approval/Approval Copies Latest 17-18 &amp; 18-19.pdf"
                                        style="color: #f2622d;"><img src="../../assets/images/pdf_logo.png"
                                            style="height: 50px;"><br>
                                        Approval Copies Latest 17-18 &amp; 18-19</a>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" id="headingnext">
                    <h2 class="mb-0">
                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse"
                            data-target="#collapsenext" aria-expanded="false" aria-controls="collapsenext"
                            style="color: #000;font-weight: bold;float: left;">
                            MSBTE(Maharashtra State Board of Technical Education)
                        </button>
                    </h2>
                </div>
                <div id="collapsenext" class="collapse" aria-labelledby="headingnext" data-parent="#accordionExample">
                    <div class="card-body">
                        <div class="container text-center">
                            <div class="row">
                                <div class="col-md-4">
                                    <a target="_blank" href="../../assets/pdf/approval/Affiliation 21-22.pdf"
                                        style="color: #f2622d;"><img src="../../assets/images/pdf_logo.png"
                                            style="height: 50px;"><br>
                                        Affilation Certificate 2021-22</a>
                                </div>
                                <div class="col-md-4">
                                    <a target="_blank" href="../../assets/pdf/approval/Affiliation 20-21.pdf"
                                        style="color: #f2622d;"><img src="../../assets/images/pdf_logo.png"
                                            style="height: 50px;"><br>
                                        Affilation Certificate 2020-21</a>
                                </div>
                                <div class="col-md-4">
                                    <a target="_blank" href="../../assets/pdf/approval/pdf" style="color: #f2622d;"><img
                                            src="../../assets/images/pdf_logo.png" style="height: 50px;"><br>
                                        Affilation Certificate 2019-20</a>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" id="headingThree">
                    <h2 class="mb-0">
                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse"
                            data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour"
                            style="color: #000;font-weight: bold;float: left;">
                            SGBAU (Sant Gadge Baba Amravati University)
                        </button>
                    </h2>
                </div>
                <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                    <div class="card-body">
                        <div class="container text-center">
                            <div class="row">
                                <div class="col-md-2">
                                    <a target="_blank" href="../../assets/pdf/approval/Affiliation 2021-22.pdf"
                                        style="color: #f2622d;"><img src="../../assets/images/pdf_logo.png"
                                            style="height: 50px;"><br>
                                        Affiliation 2021-22</a>
                                </div>
                                <div class="col-md-2">
                                    <a target="_blank" href="../../assets/pdf/approval/Affiliation 2020-21.pdf"
                                        style="color: #f2622d;"><img src="../../assets/images/pdf_logo.png"
                                            style="height: 50px;"><br>
                                        Affiliation 2020-21</a>
                                </div>
                                <div class="col-md-2">
                                    <a target="_blank" href="../../assets/pdf/approval/Affiliation 2019-20.pdf"
                                        style="color: #f2622d;"><img src="../../assets/images/pdf_logo.png"
                                            style="height: 50px;"><br>
                                        Affiliation 2019-20</a>
                                </div>
                                <div class="col-md-2">
                                    <a target="_blank" href="../../assets/pdf/approval/Affiliation 2018-19.pdf"
                                        style="color: #f2622d;"><img src="../../assets/images/pdf_logo.png"
                                            style="height: 50px;"><br>
                                        Affiliation 2018-19</a>
                                </div>
                                <div class="col-md-2">
                                    <a target="_blank" href="../../assets/pdf/approval/Affilation 2017-18.pdf"
                                        style="color: #f2622d;"><img src="../../assets/images/pdf_logo.png"
                                            style="height: 50px;"><br>
                                        Affilation 2017-18</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>