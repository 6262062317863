<div class="content-block">
    <app-header-banner [data]="banner"></app-header-banner>
    <div class="container" style="margin-top: 10px">
        <div class="row">
            <div class="col-md-3">
                <div style="background-color: rgb(241, 241, 241);padding: 1rem;margin: 10px;border: 1px solid grey;border-radius: 10px;
                color: black;height: 7rem;display: flex;align-items: center;justify-content: center;">
                    <div class="et_pb_text_inner">
                        <p style="text-align: center;"><strong>Self-Study Report</strong><br>
                            <a href="#">(View/Download)</a>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div style="background-color: rgb(241, 241, 241);padding: 1rem;margin: 10px;border: 1px solid grey;border-radius: 10px;
                color: black;height: 7rem;display: flex;align-items: center;justify-content: center;">
                    <div class="et_pb_text_inner">
                        <p style="text-align: center;"><strong>I. Extended Profile</strong><br>
                            <a href="https://prpotepatilengg.ac.in/NAAC/Extended_Profile.pdf">(View/Download)</a>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div style="background-color: rgb(241, 241, 241);padding: 1rem;margin: 10px;border: 1px solid grey;border-radius: 10px;
                color: black;height: 7rem;display: flex;align-items: center;justify-content: center;">
                    <div class="et_pb_text_inner">
                        <p style="text-align: center;"><strong>Undertaking submitted by Head of the Institute with
                                IIQA</strong><br>
                            <a href="https://prpotepatilengg.ac.in/NAAC/UndertakingByHead.pdf">(View/Download)</a>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div style="background-color: rgb(241, 241, 241);padding: 1rem;margin: 10px;border: 1px solid grey;border-radius: 10px;
                color: black;height: 7rem;display: flex;align-items: center;justify-content: center;">
                    <div class="et_pb_text_inner">
                        <p style="text-align: center;"><strong>II. Quantitative (QnM) and Qualitative (QlM)
                                Parameters</strong><br>
                            <a href="#">(View/Download)</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="et_pb_row et_pb_row_1">
            <div
                class="et_pb_column et_pb_column_4_4 et_pb_column_4  et_pb_css_mix_blend_mode_passthrough et-last-child">


                <div class="et_pb_module et_pb_accordion et_pb_accordion_0">


                    <div
                        class="et_pb_toggle et_pb_module et_pb_accordion_item et_pb_accordion_item_0 et_pb_toggle_close">


                        <h5 class="et_pb_toggle_title"></h5>
                        <div class="et_pb_toggle_content clearfix" style="display: none;"></div>

                    </div>
                    <div
                        class="et_pb_toggle et_pb_module et_pb_accordion_item et_pb_accordion_item_1 et_pb_toggle_open">


                        <h5 class="et_pb_toggle_title">Criterion 1 Curricular Aspects</h5>
                        <hr>
                        <div class="et_pb_toggle_content clearfix" style="display: block;">
                            <div class="su-table su-table-alternate">
                                <p></p>
                                <table style="width: 950px;">
                                    <tbody>
                                        <tr>
                                            <td colspan="4" style="width: 916px;">
                                                <p style="text-align: center;"><span style="color: #ff0000;"><b>Key
                                                            Indicator – 1.1 Curricular Planning and
                                                            Implementation</b></span></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width: 62px;">
                                                <p><b>1.1.1</b></p>
                                            </td>
                                            <td style="width: 80px;">
                                                <p><b>QlM</b></p>
                                            </td>
                                            <td style="width: 598.656px;">
                                                <p><b>The Institution ensures effective curriculum planning and delivery
                                                        and conduct of continuous internal Assessment</b></p>
                                            </td>
                                            <td style="width: 175.344px;">
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/1.1.1.pdf"><b>Proof-
                                                            Click here</b></a></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="4" style="width: 916px;">
                                                <p style="text-align: center;"><span style="color: #ff0000;"><b>Key
                                                            Indicator- 1.2 Academic Flexibility</b></span></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width: 62px;">
                                                <p><b>1.2.1</b></p>
                                            </td>
                                            <td style="width: 80px;">
                                                <p><b>QnM</b></p>
                                            </td>
                                            <td style="width: 598.656px;">
                                                <p><b>Number of Add on /Certificate/Value added programs offered during
                                                        the last five years</b></p>
                                            </td>
                                            <td style="width: 175.344px;">
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/1.2.1.pdf"><b>Proof-
                                                            Click here</b></a></p>
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/1.2.1.xlsx"><b>Excel-
                                                            Click here</b></a></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width: 62px;">
                                                <p><b>1.2.2</b></p>
                                            </td>
                                            <td style="width: 80px;">
                                                <p><b>QnM</b></p>
                                            </td>
                                            <td style="width: 598.656px;">
                                                <p><b>Percentage of students enrolled in Certificate/ Add-on/Value added
                                                        programs as against the total number of students during the last
                                                        five years</b></p>
                                            </td>
                                            <td style="width: 175.344px;">
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/1.2.2.pdf"><b>Proof-
                                                            Click here</b></a></p>
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/1.2.2.xlsx"><b>Excel-
                                                            Click here</b></a></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="4" style="width: 916px;">
                                                <p style="text-align: center;"><span style="color: #ff0000;"><b>Key
                                                            Indicator- 1.3 Curriculum Enrichment</b></span></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width: 62px;">
                                                <p><b>1.3.1</b></p>
                                            </td>
                                            <td style="width: 80px;">
                                                <p><b>QlM</b></p>
                                            </td>
                                            <td style="width: 598.656px;">
                                                <p><b>Institution integrates crosscutting issues relevant to
                                                        Professional Ethics, Gender, Human Values, Environment and
                                                        Sustainability into the Curriculum</b></p>
                                            </td>
                                            <td style="width: 175.344px;">
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/1.3.1.pdf"><b>Proof-
                                                            Click here</b></a></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width: 62px;">
                                                <p><b>1.3.2</b></p>
                                            </td>
                                            <td style="width: 80px;">
                                                <p><b>QnM</b></p>
                                            </td>
                                            <td style="width: 598.656px;">
                                                <p><b>Percentage of students undertaking project work/field
                                                        work/internships</b></p>
                                            </td>
                                            <td style="width: 175.344px;">
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/1.3.2.pdf"><b>Proof-
                                                            Click here</b></a></p>
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/1.3.2.xlsx"><b>Excel-
                                                            Click here</b></a></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="4" style="width: 916px;">
                                                <p style="text-align: center;"><span style="color: #ff0000;"><b>Key
                                                            Indicator- 1.4 Feedback System</b></span></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width: 62px;">
                                                <p><b>1.4.1</b></p>
                                            </td>
                                            <td style="width: 80px;">
                                                <p><b>QnM</b></p>
                                            </td>
                                            <td style="width: 598.656px;">
                                                <p><b>Institution obtains feedback on the academic performance and
                                                        ambience of the institution from various stakeholders and action
                                                        taken report on the feedback is made available on institutional
                                                        website</b></p>
                                            </td>
                                            <td style="width: 175.344px;">
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/1.4.1.pdf"><b>Proof-
                                                            Click here</b></a></p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p></p>
                            </div>
                        </div>
                    </div>
                    <div
                        class="et_pb_toggle et_pb_module et_pb_accordion_item et_pb_accordion_item_2  et_pb_toggle_close">


                        <h5 class="et_pb_toggle_title">Criterion 2 Teaching Learning and Evaluation</h5>
                        <hr>
                        <div class="et_pb_toggle_content clearfix">
                            <div class="su-table su-table-alternate">
                                <p></p>
                                <table style="width: 950px;">
                                    <tbody>
                                        <tr>
                                            <td colspan="4" style="width: 916px;">
                                                <p style="text-align: center;"><span style="color: #ff0000;"><b>Key
                                                            Indicator- 2.1. Student Enrolment and Profile</b></span></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width: 62px;">
                                                <p><b>2.1.1</b></p>
                                            </td>
                                            <td style="width: 62px;">
                                                <p><b>QnM</b></p>
                                            </td>
                                            <td style="width: 608.469px;">
                                                <p><b>Enrolment percentage</b></p>
                                            </td>
                                            <td style="width: 183.531px;">
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/2.1.1.pdf"><b>Proof-
                                                            Click here</b></a></p>
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/2.1.1.xlsx"><b>Excel-
                                                            Click here</b></a></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width: 62px;">
                                                <p><b>2.1.2</b></p>
                                            </td>
                                            <td style="width: 62px;">
                                                <p><b>QnM</b></p>
                                            </td>
                                            <td style="width: 608.469px;">
                                                <p><b>Percentage of seats filled against seats reserved for various
                                                        categories (SC, ST, OBC, Divyangjan, etc. as per applicable
                                                        reservation policy during the last five years</b></p>
                                            </td>
                                            <td style="width: 183.531px;">
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/2.1.2.pdf"><b>Proof-
                                                            Click here</b></a></p>
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/2.1.2.xlsx"><b>Excel-
                                                            Click here</b></a></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="4" style="width: 916px;">
                                                <p style="text-align: center;"><span style="color: #ff0000;"><b>Key
                                                            Indicator- 2.2 </b><b>Student Teacher Ratio</b></span></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width: 62px;">
                                                <p><b>2.2.1</b></p>
                                            </td>
                                            <td style="width: 62px;">
                                                <p><b>QnM</b></p>
                                            </td>
                                            <td style="width: 608.469px;">
                                                <p><b>Student – Full time Teacher Ratio</b></p>
                                            </td>
                                            <td style="width: 183.531px;">
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/2.2.1.pdf"><b>Proof-
                                                            Click here</b></a></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="4" style="width: 916px;">
                                                <p style="text-align: center;"><span style="color: #ff0000;"><b>Key
                                                            Indicator- 2.3 Teaching- Learning Process</b></span></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width: 62px;">
                                                <p><b>2.3.1</b></p>
                                            </td>
                                            <td style="width: 62px;">
                                                <p><b>QlM</b></p>
                                            </td>
                                            <td style="width: 608.469px;">
                                                <p><b>Student centric methods, such as experiential learning,
                                                        participative learning and problem solving methodologies are
                                                        used for enhancing learning experiences using ICT tools</b></p>
                                            </td>
                                            <td style="width: 183.531px;">
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/2.3.1.pdf"><b>Proof-
                                                            Click here</b></a></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="4" style="width: 916px;">
                                                <p style="text-align: center;"><span style="color: #ff0000;"><b>Key
                                                            Indicator- 2.4 Teacher Profile and Quality</b></span></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width: 62px;">
                                                <p><b>2.4.1</b></p>
                                            </td>
                                            <td style="width: 62px;">
                                                <p><b>QnM</b></p>
                                            </td>
                                            <td style="width: 608.469px;">
                                                <p><b>Percentage of full-time teachers against sanctioned posts during
                                                        the last five years</b></p>
                                            </td>
                                            <td style="width: 183.531px;">
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/2.4.1.pdf"><b>Proof-
                                                            Click here</b></a></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width: 62px;">
                                                <p><b>2.4.2</b></p>
                                            </td>
                                            <td style="width: 62px;">
                                                <p><b>QnM</b></p>
                                            </td>
                                            <td style="width: 608.469px;">
                                                <p><b>Percentage of full time teachers with NET/SET/SLET/ Ph. D. / D.M.
                                                        /M.Ch. / D.N.B Superspeciality / D.Sc. / D.Litt. during the last
                                                        five years</b></p>
                                            </td>
                                            <td style="width: 183.531px;">
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/2.4.2.pdf"><b>Proof-
                                                            Click here</b></a></p>
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/2.4.2.xlsx"><b>Excel-
                                                            Click here</b></a></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="4" style="width: 916px;">
                                                <p style="text-align: center;"><span style="color: #ff0000;"><b>Key
                                                            Indicator- 2.5. Evaluation Process and Reforms</b></span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width: 62px;">
                                                <p><b>2.5.1</b></p>
                                            </td>
                                            <td style="width: 62px;">
                                                <p><b>QlM</b></p>
                                            </td>
                                            <td style="width: 608.469px;">
                                                <p><b>Mechanism of internal/ external assessment is transparent and the
                                                        grievance redressal system is time- bound and efficient</b></p>
                                            </td>
                                            <td style="width: 183.531px;">
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/2.5.1.pdf"><b>Proof-
                                                            Click here</b></a></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="4" style="width: 916px;">
                                                <p style="text-align: center;"><span style="color: #ff0000;"><b>Key
                                                            Indicator- 2.6 Student Performance and Learning
                                                            Outcome</b></span></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width: 62px;">
                                                <p><b>2.6.1</b></p>
                                            </td>
                                            <td style="width: 62px;">
                                                <p><b>QlM</b></p>
                                            </td>
                                            <td style="width: 608.469px;">
                                                <p><b>Programme Outcomes (POs) and Course Outcomes (COs) for all
                                                        Programmes offered by the institution are stated and displayed
                                                        on website and attainment of POs and COs are evaluated</b></p>
                                            </td>
                                            <td style="width: 183.531px;">
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/2.6.1.pdf"><b>Proof-
                                                            Click here</b></a></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width: 62px;">
                                                <p><b>2.6.2</b></p>
                                            </td>
                                            <td style="width: 62px;">
                                                <p><b>QnM</b></p>
                                            </td>
                                            <td style="width: 608.469px;">
                                                <p><b>Pass percentage of Students during last five years</b></p>
                                            </td>
                                            <td style="width: 183.531px;">
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/2.6.2.pdf"><b>Proof-
                                                            Click here</b></a></p>
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/2.6.2.xlsx"><b>Excel-
                                                            Click here</b></a></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="4" style="width: 916px;">
                                                <p style="text-align: center;"><span style="color: #ff0000;"><b>Key
                                                            Indicator- 2.7 Student Satisfaction Survey</b></span></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width: 62px;">
                                                <p><b>2.7.1</b></p>
                                            </td>
                                            <td style="width: 62px;">
                                                <p><b>QnM</b></p>
                                            </td>
                                            <td style="width: 608.469px;">
                                                <p><b>Online student satisfaction survey regarding to teaching learning
                                                        process</b></p>
                                            </td>
                                            <td style="width: 183.531px;">
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/2.7.1.pdf"><b>Proof-
                                                            Click here</b></a></p>
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/2.7.1.xlsx"><b>Excel-
                                                            Click here</b></a></p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p></p>
                            </div>
                        </div>
                    </div>
                    <div
                        class="et_pb_toggle et_pb_module et_pb_accordion_item et_pb_accordion_item_3  et_pb_toggle_close">


                        <h5 class="et_pb_toggle_title">Criterion 3 Research, Innovations, and Extension</h5>
                        <hr>
                        <div class="et_pb_toggle_content clearfix">
                            <div class="su-table su-table-alternate">
                                <p></p>
                                <table style="width: 950px;">
                                    <tbody>
                                        <tr>
                                            <td colspan="4" style="width: 918px;">
                                                <p style="text-align: center;"><span style="color: #ff0000;"><b>Key
                                                            Indicator 3.1- Resource Mobilization for Research</b></span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width: 70px;">
                                                <p><b>3.1.1</b></p>
                                            </td>
                                            <td style="width: 76px;">
                                                <p><b>QnM</b></p>
                                            </td>
                                            <td style="width: 596.703px;">
                                                <p><b>Grants received from Government and non-governmental agencies for
                                                        research projects / endowments in the institution during the
                                                        last five years</b></p>
                                            </td>
                                            <td style="width: 175.297px;">
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/3.1.1.pdf"><b>Proof-
                                                            Click here</b></a></p>
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/3.1.1.xlsx"><b>Excel-
                                                            Click here</b></a></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="4" style="width: 918px;">
                                                <p style="text-align: center;"><span style="color: #ff0000;"><b>Key
                                                            Indicator- 3.2 </b><b>Innovation Ecosystem</b></span></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width: 70px;">
                                                <p><b>3.2.1</b></p>
                                            </td>
                                            <td style="width: 76px;">
                                                <p><b>QlM</b></p>
                                            </td>
                                            <td style="width: 596.703px;">
                                                <p><b>Institution has created an ecosystem for innovations and has
                                                        initiatives for creation and transfer of knowledge</b></p>
                                            </td>
                                            <td style="width: 175.297px;">
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/3.2.1.pdf"><b>Proof-
                                                            Click here</b></a></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width: 70px;">
                                                <p><b>3.2.2</b></p>
                                            </td>
                                            <td style="width: 76px;">
                                                <p><b>QnM</b></p>
                                            </td>
                                            <td style="width: 596.703px;">
                                                <p><b>Number of workshops/seminars/conferences including on Research
                                                        Methodology, Intellectual Property Rights (IPR) and
                                                        entrepreneurship conducted during the last five years</b></p>
                                            </td>
                                            <td style="width: 175.297px;">
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/3.2.2.pdf"><b>Proof-
                                                            Click here</b></a></p>
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/3.2.2.xlsx"><b>Excel-
                                                            Click here</b></a></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="4" style="width: 918px;">
                                                <p style="text-align: center;"><span style="color: #ff0000;"><b>Key
                                                            Indicator- 3.3 Research Publication and Awards</b></span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width: 70px;">
                                                <p><b>3.3.1</b></p>
                                            </td>
                                            <td style="width: 76px;">
                                                <p><b>QnM</b></p>
                                            </td>
                                            <td style="width: 596.703px;">
                                                <p><b>Number of research papers published per teacher in the Journals
                                                        notified on UGC care list during the last five years</b></p>
                                            </td>
                                            <td style="width: 175.297px;">
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/3.3.1.pdf"><b>Proof-
                                                            Click here</b></a></p>
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/3.3.1.xlsx"><b>Excel-
                                                            Click here</b></a></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width: 70px;">
                                                <p><b>3.3.2</b></p>
                                            </td>
                                            <td style="width: 76px;">
                                                <p><b>QnM</b></p>
                                            </td>
                                            <td style="width: 596.703px;">
                                                <p><b>Number of books and chapters in edited volumes/books published and
                                                        papers published in national/ international conference
                                                        proceedings per teacher during last five years</b></p>
                                            </td>
                                            <td style="width: 175.297px;">
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/3.3.2.pdf"><b>Proof-
                                                            Click here</b></a></p>
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/3.3.2.xlsx"><b>Excel-
                                                            Click here</b></a></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="4" style="width: 918px;">
                                                <p style="text-align: center;"><span style="color: #ff0000;"><b>Key
                                                            Indicator- 3.4 Extension Activities</b></span></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width: 70px;">
                                                <p><b>3.4.1</b></p>
                                            </td>
                                            <td style="width: 76px;">
                                                <p><b>QlM</b></p>
                                            </td>
                                            <td style="width: 596.703px;">
                                                <p><b>Extension activities are carried out in the neighbourhood
                                                        community, sensitizing students to social issues, for their
                                                        holistic development, and impact thereof.</b></p>
                                            </td>
                                            <td style="width: 175.297px;">
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/3.4.1.pdf"><b>Proof-
                                                            Click here</b></a></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width: 70px;">
                                                <p><b>3.4.2</b></p>
                                            </td>
                                            <td style="width: 76px;">
                                                <p><b>QlM</b></p>
                                            </td>
                                            <td style="width: 596.703px;">
                                                <p><b>Awards and recognitions received for extension activities from
                                                        government / government recognised bodies</b></p>
                                            </td>
                                            <td style="width: 175.297px;">
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/3.4.2.pdf"><b>Proof-
                                                            Click here</b></a></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width: 70px;">
                                                <p><b>3.4.3</b></p>
                                            </td>
                                            <td style="width: 76px;">
                                                <p><b>QnM</b></p>
                                            </td>
                                            <td style="width: 596.703px;">
                                                <p><b>Number of extension and outreach programs conducted by the
                                                        institution through NSS/NCC/Red cross/YRC etc., during the last
                                                        five years</b></p>
                                            </td>
                                            <td style="width: 175.297px;">
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/3.4.3.pdf"><b>Proof-
                                                            Click here</b></a></p>
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/3.4.3.xlsx"><b>Excel-
                                                            Click here</b></a></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="4" style="width: 918px;">
                                                <p style="text-align: center;"><span style="color: #ff0000;"><b>Key
                                                            Indicator- 3.5. Collaboration</b></span></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width: 70px;">
                                                <p><b>3.5.1</b></p>
                                            </td>
                                            <td style="width: 76px;">
                                                <p><b>QnM</b></p>
                                            </td>
                                            <td style="width: 596.703px;">
                                                <p><b>The number of MoUs, collaborations/linkages for Faculty exchange,
                                                        Student exchange, Internship, Field trip, On-the- job training,
                                                        research and other academic activities, during the last five
                                                        years</b></p>
                                            </td>
                                            <td style="width: 175.297px;">
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/3.5.1.pdf"><b>Proof-
                                                            Click here</b></a></p>
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/3.5.1.xlsx"><b>Excel-
                                                            Click here</b></a></p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p></p>
                            </div>
                        </div>
                    </div>
                    <div
                        class="et_pb_toggle et_pb_module et_pb_accordion_item et_pb_accordion_item_4  et_pb_toggle_close">


                        <h5 class="et_pb_toggle_title">Criterion 4 Infrastructure and Learning Resources</h5>
                        <hr>
                        <div class="et_pb_toggle_content clearfix">
                            <div class="su-table su-table-alternate">
                                <p></p>
                                <table style="width: 950px;">
                                    <tbody>
                                        <tr>
                                            <td colspan="4" style="width: 916px;">
                                                <p style="text-align: center;"><span style="color: #ff0000;"><b>Key
                                                            Indicator 4.1- Physical Facilities</b></span></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width: 64px;">
                                                <p><b>4.1.1</b></p>
                                            </td>
                                            <td style="width: 74px;">
                                                <p><b>QlM</b></p>
                                            </td>
                                            <td style="width: 606.453px;">
                                                <p><b>Availability of adequate infrastructure and physical facilities
                                                        viz., classrooms, laboratories, ICT facilities, cultural
                                                        activities, gymnasium, yoga centre etc. in the institution</b>
                                                </p>
                                            </td>
                                            <td style="width: 171.547px;">
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/4.1.1.pdf"><b>Proof-
                                                            Click here</b></a></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width: 64px;">
                                                <p><b>4.1.2</b></p>
                                            </td>
                                            <td style="width: 74px;">
                                                <p><b>QnM</b></p>
                                            </td>
                                            <td style="width: 606.453px;">
                                                <p><b>Percentage of expenditure, excluding salary for infrastructure
                                                        augmentation during last five years</b></p>
                                            </td>
                                            <td style="width: 171.547px;">
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/4.1.2.pdf"><b>Proof-
                                                            Click here</b></a></p>
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/4.1.2.xlsx"><b>Excel-
                                                            Click here</b></a></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="4" style="width: 916px;">
                                                <p style="text-align: center;"><span style="color: #ff0000;"><b>Key
                                                            Indicator- </b><b>4.2 Library as a learning
                                                            Resource</b></span></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width: 64px;">
                                                <p><b>4.2.1</b></p>
                                            </td>
                                            <td style="width: 74px;">
                                                <p><b>QlM</b></p>
                                            </td>
                                            <td style="width: 606.453px;">
                                                <p><b>Library is automated using Integrated Library Management System
                                                        (ILMS), subscription to e-resources, amount spent on purchase of
                                                        books, journals and per day usage of library</b></p>
                                            </td>
                                            <td style="width: 171.547px;">
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/4.2.1.pdf"><b>Proof-
                                                            Click here</b></a></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="4" style="width: 916px;">
                                                <p style="text-align: center;"><span style="color: #ff0000;"><b>Key
                                                            Indicator- 4.3 IT Infrastructure</b></span></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width: 64px;">
                                                <p><b>4.3.1</b></p>
                                            </td>
                                            <td style="width: 74px;">
                                                <p><b>QlM</b></p>
                                            </td>
                                            <td style="width: 606.453px;">
                                                <p><b>Institution frequently updates its IT facilities and provides
                                                        sufficient bandwidth for internet connection</b></p>
                                            </td>
                                            <td style="width: 171.547px;">
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/4.3.1.pdf"><b>Proof-
                                                            Click here</b></a></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width: 64px;">
                                                <p><b>4.3.2</b></p>
                                            </td>
                                            <td style="width: 74px;">
                                                <p><b>QnM</b></p>
                                            </td>
                                            <td style="width: 606.453px;">
                                                <p><b>Student – Computer ratio&nbsp;</b></p>
                                            </td>
                                            <td style="width: 171.547px;">
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/4.3.2.pdf"><b>Proof-
                                                            Click here</b></a></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="4" style="width: 916px;">
                                                <p style="text-align: center;"><span style="color: #ff0000;"><b>Key
                                                            Indicator- 4.4 Maintenance of Campus
                                                            Infrastructure</b></span></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width: 64px;">
                                                <p><b>4.4.1</b></p>
                                            </td>
                                            <td style="width: 74px;">
                                                <p><b>QnM</b></p>
                                            </td>
                                            <td style="width: 606.453px;">
                                                <p><b>Percentage of expenditure incurred on maintenance of
                                                        infrastructure excluding salary component during the last five
                                                        years</b></p>
                                            </td>
                                            <td style="width: 171.547px;">
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/4.4.1.pdf"><b>Proof-
                                                            Click here</b></a></p>
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/4.4.1.xlsx"><b>Excel-
                                                            Click here</b></a></p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p></p>
                            </div>
                        </div>
                    </div>
                    <div
                        class="et_pb_toggle et_pb_module et_pb_accordion_item et_pb_accordion_item_5  et_pb_toggle_close">


                        <h5 class="et_pb_toggle_title">Criterion 5 Student Support and Progression</h5>
                        <hr>
                        <div class="et_pb_toggle_content clearfix">
                            <div class="su-table su-table-alternate">
                                <p></p>
                                <table style="width: 950px;">
                                    <tbody>
                                        <tr>
                                            <td colspan="4" style="width: 923px;">
                                                <p style="text-align: center;"><span style="color: #ff0000;"><b>Key
                                                            Indicator 5.1- Student Support</b></span></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width: 75px;">
                                                <p><b>5.1.1</b></p>
                                            </td>
                                            <td style="width: 86px;">
                                                <p><b>QnM</b></p>
                                            </td>
                                            <td style="width: 571.078px;">
                                                <p><b>Percentage of students benefited by scholarships and freeships
                                                        provided by the Government and Non-Government agencies during
                                                        last five years</b></p>
                                            </td>
                                            <td style="width: 190.922px;">
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/5.1.1.pdf"><b>Proof-
                                                            Click here</b></a></p>
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/5.1.1.xlsx"><b>Excel-
                                                            Click here</b></a></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width: 75px;">
                                                <p><b>5.1.2</b></p>
                                            </td>
                                            <td style="width: 86px;">
                                                <p><b>QnM</b></p>
                                            </td>
                                            <td style="width: 571.078px;">
                                                <p><b>Capacity building and skills enhancement initiatives taken by the
                                                        institution include the following </b><b>1. Soft skills 2.
                                                        Language and communication skills 3. Life skills (Yoga, physical
                                                        fitness, health and hygiene) 4. ICT/computing skills</b></p>
                                            </td>
                                            <td style="width: 190.922px;">
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/5.1.2.pdf"><b>Proof-
                                                            Click here</b></a></p>
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/5.1.2.xlsx"><b>Excel-
                                                            Click here</b></a></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width: 75px;">
                                                <p><b>5.1.3</b></p>
                                            </td>
                                            <td style="width: 86px;">
                                                <p><b>QnM</b></p>
                                            </td>
                                            <td style="width: 571.078px;">
                                                <p><b>Percentage of students benefitted by guidance for competitive
                                                        examinations and career counseling offered by the Institution
                                                        during the last five years</b></p>
                                            </td>
                                            <td style="width: 190.922px;">
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/5.1.3.pdf"><b>Proof-
                                                            Click here</b></a></p>
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/5.1.3.xlsx"><b>Excel-
                                                            Click here</b></a></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width: 75px;">
                                                <p><b>5.1.4</b></p>
                                            </td>
                                            <td style="width: 86px;">
                                                <p><b>QnM</b></p>
                                            </td>
                                            <td style="width: 571.078px;">
                                                <p><b>The Institution has a transparent mechanism for timely redressal
                                                        of student grievances including sexual harassment and ragging
                                                        cases</b></p>
                                            </td>
                                            <td style="width: 190.922px;">
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/5.1.4.pdf"><b>Proof-
                                                            Click here</b></a></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="4" style="width: 923px;">
                                                <p style="text-align: center;"><span style="color: #ff0000;"><b>Key
                                                            Indicator- 5.2 </b><b>Student Progression</b></span></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width: 75px;">
                                                <p><b>5.2.1</b></p>
                                            </td>
                                            <td style="width: 86px;">
                                                <p><b>QnM</b></p>
                                            </td>
                                            <td style="width: 571.078px;">
                                                <p><b>Percentage of placement of outgoing students and students
                                                        progressing to higher education during the last five years</b>
                                                </p>
                                            </td>
                                            <td style="width: 190.922px;">
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/5.2.1.pdf"><b>Proof-
                                                            Click here</b></a></p>
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/5.2.1.xlsx"><b>Excel-
                                                            Click here</b></a></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width: 75px;">
                                                <p><b>5.2.2</b></p>
                                            </td>
                                            <td style="width: 86px;">
                                                <p><b>QnM</b></p>
                                            </td>
                                            <td style="width: 571.078px;">
                                                <p><b>Percentage of students qualifying in state/national/ international
                                                        level examinations during the last five years&nbsp;</b></p>
                                            </td>
                                            <td style="width: 190.922px;">
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/5.2.2.pdf"><b>Proof-
                                                            Click here</b></a></p>
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/5.2.2.xls"><b>Excel-
                                                            Click here</b></a></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="4" style="width: 923px;">
                                                <p style="text-align: center;"><span style="color: #ff0000;"><b>Key
                                                            Indicator- 5.3 Student Participation and
                                                            Activities&nbsp;</b></span></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width: 75px;">
                                                <p><b>5.3.1</b></p>
                                            </td>
                                            <td style="width: 86px;">
                                                <p><b>QnM</b></p>
                                            </td>
                                            <td style="width: 571.078px;">
                                                <p><b>Number of awards/medals for outstanding performance in
                                                        sports/cultural activities at University / state/ national /
                                                        international level during the last five years</b></p>
                                            </td>
                                            <td style="width: 190.922px;">
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/5.3.1.pdf"><b>Proof-
                                                            Click here</b></a></p>
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/5.3.1.xlsx"><b>Excel-
                                                            Click here</b></a></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width: 75px;">
                                                <p><b>5.3.2</b></p>
                                            </td>
                                            <td style="width: 86px;">
                                                <p><b>QnM</b></p>
                                            </td>
                                            <td style="width: 571.078px;">
                                                <p><b>Average number of sports and cultural programs in which students
                                                        of the Institution participated during last five years</b></p>
                                            </td>
                                            <td style="width: 190.922px;">
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/5.3.2.pdf"><b>Proof-
                                                            Click here</b></a></p>
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/5.3.2.xlsx"><b>Excel-
                                                            Click here</b></a></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="4" style="width: 923px;">
                                                <p style="text-align: center;"><span style="color: #ff0000;"><b>Key
                                                            Indicator- 5.4 Alumni Engagement&nbsp;</b></span></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width: 75px;">
                                                <p><b>5.4.1</b></p>
                                            </td>
                                            <td style="width: 86px;">
                                                <p><b>QlM</b></p>
                                            </td>
                                            <td style="width: 571.078px;">
                                                <p><b>There is a registered Alumni Association that contributes
                                                        significantly to the development of the institution through
                                                        financial and/or other support services</b></p>
                                            </td>
                                            <td style="width: 190.922px;">
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/5.4.1.pdf"><b>Proof-
                                                            Click here</b></a></p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p></p>
                            </div>
                        </div>
                    </div>
                    <div
                        class="et_pb_toggle et_pb_module et_pb_accordion_item et_pb_accordion_item_6  et_pb_toggle_close">


                        <h5 class="et_pb_toggle_title">Criterion 6 Governance, Leadership, and Management</h5>
                        <hr>
                        <div class="et_pb_toggle_content clearfix">
                            <div class="su-table su-table-alternate">
                                <p></p>
                                <table style="width: 950px;">
                                    <tbody>
                                        <tr>
                                            <td colspan="4" style="width: 924px;">
                                                <p style="text-align: center;"><span style="color: #ff0000;"><b>Key
                                                            Indicator- 6.1 Institutional Vision and
                                                            Leadership</b></span></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width: 76px;"><b>6.1.1</b></td>
                                            <td style="width: 76px;"><b>QlM</b></td>
                                            <td style="width: 601.062px;"><b>The governance and leadership is in
                                                    accordance with vision and mission of the institution and it is
                                                    visible in various institutional practices such as decentralization
                                                    and participation in the institutional governance</b></td>
                                            <td style="width: 170.938px;"><a
                                                    href="https://prpotepatilengg.ac.in/NAAC/6.1.1.pdf"><b>Proof- Click
                                                        here</b></a></td>
                                        </tr>
                                        <tr>
                                            <td colspan="4" style="width: 924px;">
                                                <p style="text-align: center;"><span style="color: #ff0000;"><b>Key
                                                            Indicator- 6.2 </b><b>Strategy Development and
                                                            Deployment&nbsp;</b></span></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width: 76px;"><b>6.2.1</b></td>
                                            <td style="width: 76px;"><b>QlM</b></td>
                                            <td style="width: 601.062px;"><b>The functioning of the institutional bodies
                                                    is effective and efficient as visible from policies, administrative
                                                    setup, appointment and service rules, procedures, deployment of
                                                    institutional Strategic/perspective/development plan etc</b></td>
                                            <td style="width: 170.938px;"><a
                                                    href="https://prpotepatilengg.ac.in/NAAC/6.2.1.pdf"><b>Proof- Click
                                                        here</b></a></td>
                                        </tr>
                                        <tr>
                                            <td style="width: 76px;"><b>6.2.2</b></td>
                                            <td style="width: 76px;"><b>QnM</b></td>
                                            <td style="width: 601.062px;"><b>Implementation of e-governance in areas of
                                                    operation 1. Administration 2. Finance and Accounts 3. Student
                                                    Admission and Support 4. Examination</b></td>
                                            <td style="width: 170.938px;"><a
                                                    href="https://prpotepatilengg.ac.in/NAAC/6.2.2.pdf"><b>Proof- Click
                                                        here</b></a></td>
                                        </tr>
                                        <tr>
                                            <td colspan="4" style="width: 924px;">
                                                <p style="text-align: center;"><span style="color: #ff0000;"><b>Key
                                                            Indicator- 6.3 Faculty Empowerment
                                                            Strategies&nbsp;</b></span></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width: 76px;"><b>6.3.1</b></td>
                                            <td style="width: 76px;"><b>QlM</b></td>
                                            <td style="width: 601.062px;"><b>The institution has effective welfare
                                                    measures and Performance Appraisal System for teaching and
                                                    non-teaching staff</b></td>
                                            <td style="width: 170.938px;"><a
                                                    href="https://prpotepatilengg.ac.in/NAAC/6.3.1.pdf"><b>Proof- Click
                                                        here</b></a></td>
                                        </tr>
                                        <tr>
                                            <td style="width: 76px;"><b>6.3.2</b></td>
                                            <td style="width: 76px;"><b>QnM</b></td>
                                            <td style="width: 601.062px;"><b>Percentage of teachers provided with
                                                    financial support to attend conferences/workshops and towards
                                                    membership fee of professional bodies during the last five years</b>
                                            </td>
                                            <td style="width: 170.938px;"><a
                                                    href="https://prpotepatilengg.ac.in/NAAC/6.3.2.pdf"><b>Proof- Click
                                                        here</b></a>
                                                <p></p>
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/6.3.2.xlsx"><b>Excel-
                                                            Click here</b></a></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width: 76px;"><b>6.3.3</b></td>
                                            <td style="width: 76px;"><b>QnM</b></td>
                                            <td style="width: 601.062px;"><b>Percentage of teaching and non-teaching
                                                    staff participating in Faculty development Programmes (FDP),
                                                    professional development /administrative training programs during
                                                    the last five years</b></td>
                                            <td style="width: 170.938px;"><a
                                                    href="https://prpotepatilengg.ac.in/NAAC/6.3.3.pdf"><b>Proof- Click
                                                        here</b></a>
                                                <p></p>
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/6.3.3.xlsx"><b>Excel-
                                                            Click here</b></a></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="4" style="width: 924px;">
                                                <p style="text-align: center;"><span style="color: #ff0000;"><b>Key
                                                            Indicator- 6.4 Financial Management and Resource
                                                            Mobilization</b></span></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width: 76px;"><b>6.4.1</b></td>
                                            <td style="width: 76px;"><b>QlM</b></td>
                                            <td style="width: 601.062px;"><b>Institution has strategies for mobilization
                                                    and optimal utilization of resources and funds from various sources
                                                    (government/ nongovernment organizations) and it conducts financial
                                                    audits regularly (internal and external)</b></td>
                                            <td style="width: 170.938px;"><a
                                                    href="https://prpotepatilengg.ac.in/NAAC/6.4.1.pdf"><b>Proof- Click
                                                        here</b></a></td>
                                        </tr>
                                        <tr>
                                            <td colspan="4" style="width: 924px;">
                                                <p style="text-align: center;"><span style="color: #ff0000;"><b>Key
                                                            Indicator- 6.5 Internal Quality Assurance System</b></span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width: 76px;"><b>6.5.1</b></td>
                                            <td style="width: 76px;"><b>QlM</b></td>
                                            <td style="width: 601.062px;"><b>Internal Quality Assurance Cell (IQAC) has
                                                    contributed significantly for institutionalizing the quality
                                                    assurance strategies and processes. It reviews teaching learning
                                                    process, structures &amp; methodologies of operations and learning
                                                    outcomes at periodic intervals and records the incremental
                                                    improvement in various activities</b></td>
                                            <td style="width: 170.938px;"><a
                                                    href="https://prpotepatilengg.ac.in/NAAC/6.5.1.pdf"><b>Proof- Click
                                                        here</b></a></td>
                                        </tr>
                                        <tr>
                                            <td style="width: 76px;"><b>6.5.2</b></td>
                                            <td style="width: 76px;"><b>QnM</b></td>
                                            <td style="width: 601.062px;"><b>Quality assurance initiatives of the
                                                    institution include: 1. Regular meeting of Internal Quality
                                                    Assurance Cell (IQAC); Feedback collected, analysed and used for
                                                    Improvements 2. Collaborative quality initiatives with other
                                                    institution(s)/membership of international networks 3. Participation
                                                    in NIRF 4. any other quality audit/accreditation recognized by
                                                    state, national or international agencies such as NAAC, NBA, ISO
                                                    Certification etc</b></td>
                                            <td style="width: 170.938px;"><a
                                                    href="https://prpotepatilengg.ac.in/NAAC/6.5.2.pdf"><b>Proof- Click
                                                        here</b></a>
                                                <p></p>
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/6.5.2.xlsx"><b>Excel-
                                                            Click here</b></a></p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p></p>
                            </div>
                        </div>
                    </div>
                    <div
                        class="et_pb_toggle et_pb_module et_pb_accordion_item et_pb_accordion_item_7  et_pb_toggle_close">


                        <h5 class="et_pb_toggle_title">Criterion 7 Institutional Values and Best Practices</h5>
                        <hr>
                        <div class="et_pb_toggle_content clearfix">
                            <div class="su-table su-table-alternate">
                                <p></p>
                                <table style="width: 950px;">
                                    <tbody>
                                        <tr>
                                            <td colspan="4" style="width: 916px;">
                                                <p style="text-align: center;"><span style="color: #ff0000;"><b>Key
                                                            Indicator 7.1- Institutional Values and Social
                                                            Responsibilities</b></span></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="4" style="width: 916px;">
                                                <p style="text-align: center;"><span style="color: #3366ff;"><b>Gender
                                                            Equity and celebration of days of National/International
                                                            commemoration</b></span></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width: 66px;">
                                                <p><b>7.1.1</b></p>
                                            </td>
                                            <td style="width: 78px;">
                                                <p><b>QlM</b></p>
                                            </td>
                                            <td style="width: 591.578px;">
                                                <p><b>Measures initiated by the Institution for the promotion of gender
                                                        equity and Institutional initiatives to celebrate / organize
                                                        national and international commemorative days, events and
                                                        festivals during the last five years</b></p>
                                            </td>
                                            <td style="width: 180.422px;">
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/7.1.1.pdf"><b>Proof-
                                                            Click here</b></a></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="4" style="width: 916px;">
                                                <p style="text-align: center;"><span
                                                        style="color: #3366ff;"><b>Environmental Consciousness and
                                                            Sustainability and Divyangjan friendly
                                                            initiatives</b></span></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width: 66px;">
                                                <p><b>7.1.2</b></p>
                                            </td>
                                            <td style="width: 78px;">
                                                <p><b>QnM</b></p>
                                            </td>
                                            <td style="width: 591.578px;">
                                                <p><b>The Institution has facilities and initiatives for 1. Alternate
                                                        sources of energy and energy conservation measures </b><span
                                                        style="font-weight: 400;">2. </span><b>Management of the various
                                                        types of degradable and nondegradable waste </b><span
                                                        style="font-weight: 400;">3. </span><b>Water conservation
                                                    </b><span style="font-weight: 400;">4. </span><b>Green campus
                                                        initiatives 5. Disabled-friendly, barrier free environment</b>
                                                </p>
                                            </td>
                                            <td style="width: 180.422px;">
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/7.1.2.pdf"><b>Proof-
                                                            Click here</b></a></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width: 66px;">
                                                <p><b>7.1.3</b></p>
                                            </td>
                                            <td style="width: 78px;">
                                                <p><b>QnM</b></p>
                                            </td>
                                            <td style="width: 591.578px;">
                                                <p><b>Quality audits on environment and energy regularly undertaken by
                                                        the Institution. The institutional environment and energy
                                                        initiatives are confirmed through the following- 1. Green audit
                                                        / Environment audit; 2. Energy audit 3. Clean and green campus
                                                        initiatives; 4. Beyond the campus environmental promotion
                                                        activities</b></p>
                                            </td>
                                            <td style="width: 180.422px;">
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/7.1.3.pdf"><b>Proof-
                                                            Click here</b></a></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="4" style="width: 916px;">
                                                <p style="text-align: center;"><span
                                                        style="color: #3366ff;"><b>Inclusion, Situatedness, Human values
                                                            &amp; professional ethics</b></span></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width: 66px;">
                                                <p><b>7.1.4</b></p>
                                            </td>
                                            <td style="width: 78px;">
                                                <p><b>QlM</b></p>
                                            </td>
                                            <td style="width: 591.578px;">
                                                <p><b>Describe the Institutional efforts/initiatives in providing an
                                                        inclusive environment i.e., tolerance and harmony towards
                                                        cultural, regional, linguistic, communal socioeconomic and
                                                        Sensitization of students and employees to the constitutional
                                                        obligations: values, rights, duties and responsibilities of
                                                        citizens</b></p>
                                            </td>
                                            <td style="width: 180.422px;">
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/7.1.4.pdf"><b>Proof-
                                                            Click here</b></a></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="4" style="width: 916px;">
                                                <p style="text-align: center;"><span style="color: #ff0000;"><b>Key
                                                            Indicator- 7.2 Best Practices</b></span></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width: 66px;">
                                                <p><b>7.2.1</b></p>
                                            </td>
                                            <td style="width: 78px;">
                                                <p><b>QlM</b></p>
                                            </td>
                                            <td style="width: 591.578px;">
                                                <p><b>Describe two best practices successfully implemented by the
                                                        Institution as per NAAC format provided in the Manual</b></p>
                                            </td>
                                            <td style="width: 180.422px;">
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/7.2.1.pdf"><b>Proof-
                                                            Click here</b></a></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="4" style="width: 916px;">
                                                <p style="text-align: center;"><span style="color: #ff0000;"><b>Key
                                                            Indicator- 7.3 </b><b>Institutional
                                                            Distinctiveness</b></span></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width: 66px;">
                                                <p><b>7.3.1</b></p>
                                            </td>
                                            <td style="width: 78px;">
                                                <p><b>QlM</b></p>
                                            </td>
                                            <td style="width: 591.578px;">
                                                <p><b>Portray the performance of the Institution in one area distinctive
                                                        to its priority and thrust</b></p>
                                            </td>
                                            <td style="width: 180.422px;">
                                                <p><a href="https://prpotepatilengg.ac.in/NAAC/7.3.1.pdf"><b>Proof-
                                                            Click here</b></a></p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p></p>
                            </div>
                        </div>
                    </div>
                    <div
                        class="et_pb_toggle et_pb_module et_pb_accordion_item et_pb_accordion_item_8  et_pb_toggle_close">
                        <h5 class="et_pb_toggle_title">DVV</h5>
                        <hr>
                        <div class="et_pb_toggle_content clearfix">
                            <p><span style="color: #ffffff;"><a href="https://prpotepatilengg.ac.in/NAAC/DVV.pdf"
                                        style="color: #ffffff;"><span color="#0000ff">View DVV</span></a></span></p>
                        </div>
                    </div>
                </div>
            </div>


        </div>



    </div>
</div>