import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutCollegeComponent } from './about-college/about-college.component';
import { AboutGoverningBodyComponent } from './about-governing-body/about-governing-body.component';
import { CollegeDevelopmentCommitteeComponent } from './college-development-committee/college-development-committee.component';
import { CollegeOrgnanisationChartComponent } from './college-orgnanisation-chart/college-orgnanisation-chart.component';
import { ContactComponent } from './contact/contact.component';
import { HomeComponent } from './home/home.component';
import { IqacOldComponent } from './iqac-old/iqac-old.component';
import { NaacSsrComponent } from './naac-ssr/naac-ssr.component';
import { RdCellComponent } from './rd-cell/rd-cell.component';
import { YoutubeChannelComponent } from './youtube-channel/youtube-channel.component';
import { BestPracticeComponent } from './best-practice/best-practice.component';
import { WorkshopComponent } from './workshop/workshop.component';
import { RulesOfConductComponent } from './rules-of-conduct/rules-of-conduct.component';
import { SalientFeaturesComponent } from './salient-features/salient-features.component';
import { ApprovalsComponent } from './approvals/approvals.component';
import { RAndDComponent } from './r-and-d/r-and-d/r-and-d.component';
import { PublicationFacultyComponent } from './r-and-d/publication-faculty/publication-faculty.component';
import { PublicationStudentComponent } from './r-and-d/publication-student/publication-student.component';
import { PatentsComponent } from './r-and-d/patents/patents.component';
import { AwardsPresentationComponent } from './r-and-d/awards-presentation/awards-presentation.component';
import { OralPresentationComponent } from './r-and-d/oral-presentation/oral-presentation.component';
import { AwardsAccoladesComponent } from './r-and-d/awards-accolades/awards-accolades.component';
import { StudentCouncilComponent } from './student/student-council/student-council.component';
import { GrcComponent } from './student/grc/grc.component';
import { IccComponent } from './student/icc/icc.component';
import { AntiDiscComponent } from './student/anti-disc/anti-disc.component';
import { ScStComponent } from './student/sc-st/sc-st.component';
import { AntiRaggingComponent } from './student/anti-ragging/anti-ragging.component';
import { StudentEnrolledComponent } from './student/student-enrolled/student-enrolled.component';
import { AlumniAssociationComponent } from './student/alumni/alumni-association/alumni-association.component';
import { AlumniDpharmComponent } from './student/alumni/alumni-dpharm/alumni-dpharm.component';
import { AlumniBpharmComponent } from './student/alumni/alumni-bpharm/alumni-bpharm.component';
import { OutstandingAlumniComponent } from './student/alumni/outstanding-alumni/outstanding-alumni.component';
import { AlumniMeetComponent } from './student/alumni/alumni-meet/alumni-meet.component';
import { EventsComponent } from './events/events.component';
import { SportsParticipationComponent } from './sports/sports-participation/sports-participation.component';
import { SportsAwardsComponent } from './sports/sports-awards/sports-awards.component';
import { BuletinComponent } from './buletin/buletin.component';
import { NaacSsrNewComponent } from './naac-ssr-new/naac-ssr-new.component';


const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'about-college', component: AboutCollegeComponent },
  { path: 'governing-body', component: AboutGoverningBodyComponent },
  { path: 'development-committee', component: CollegeDevelopmentCommitteeComponent },
  { path: 'rules', component: RulesOfConductComponent },
  { path: 'salient', component: SalientFeaturesComponent },
  { path: 'approvals', component: ApprovalsComponent },
  { path: 'organisation-chart', component: CollegeOrgnanisationChartComponent },
  { path: 'rd-cell', component: RdCellComponent },
  { path: 'contact-us', component: ContactComponent },
  { path: 'iqac-old', component: IqacOldComponent },
  { path: 'naac-ssr-new', component: NaacSsrNewComponent },
  { path: 'naac-ssr', component: NaacSsrComponent },
  { path: 'youtube-channel', component: YoutubeChannelComponent },
  { path: 'best-practice', component: BestPracticeComponent },
  { path: 'admission', redirectTo: 'admission/first-year' },
  { path: 'fees-structure-2022-2023', redirectTo: 'admission/fees-structure' },
  { path: 'campus-drive-updates', redirectTo: 'placement/drive-update' },
  { path: 'naac', redirectTo: 'naac-ssr' },
  { path: 'campus-selection', redirectTo: 'placement/campus-selection' },
  { path: 't-p-session', component: WorkshopComponent },

  { path: 'r-and-d', component: RAndDComponent },
  { path: 'Publication-faculty', component: PublicationFacultyComponent },
  { path: 'Publication-student', component: PublicationStudentComponent },
  { path: 'patents', component: PatentsComponent },
  { path: 'poster-presentation', component: AwardsPresentationComponent },
  { path: 'oral-presentation', component: OralPresentationComponent },
  { path: 'awards-accolades', component: AwardsAccoladesComponent },

  { path: 'student-council', component: StudentCouncilComponent },
  { path: 'grc', component: GrcComponent },
  { path: 'icc', component: IccComponent },
  { path: 'anti-ragging', component: AntiRaggingComponent },
  { path: 'anti-disc', component: AntiDiscComponent },
  { path: 'sc-st', component: ScStComponent },
  { path: 'student-enrolled', component: StudentEnrolledComponent },

  { path: 'alumni-association', component: AlumniAssociationComponent },
  { path: 'alumni-dpharm', component : AlumniDpharmComponent},
  { path: 'alumni-bpharm', component : AlumniBpharmComponent},
  { path: 'outstanding-alumni', component : OutstandingAlumniComponent},
  { path: 'alumni-meet', component : AlumniMeetComponent},
  { path: 'sc-st', component : ScStComponent},

  { path: 'events', component : EventsComponent},

  {path: 'sports-participation', component: SportsParticipationComponent},
  {path: 'sports-awards', component: SportsAwardsComponent},

  {path: 'bulletin', component: BuletinComponent},
  
  

  {
    path: 'bpharm',
    loadChildren: () => import('./department/bpharm/bpharm.module').then(m => m.BpharmModule)
  },
  {
    path: 'dpharm',
    loadChildren: () => import('./department/dpharm/dpharm.module').then(m => m.DpharmModule)
  },
  {
    path: 'admission',
    loadChildren: () => import('./admission/admission.module').then(m => m.AdmissionModule)
  },
  {
    path: 'placement',
    loadChildren: () => import('./placement/placement.module').then(m => m.PlacementModule)
  },
  {
    path: 'campus',
    loadChildren: () => import('./campus/campus.module').then(m => m.CampusModule)
  },
  {
    path: 'other',
    loadChildren: () => import('./other/other.module').then(m => m.OtherModule)
  },
  {
    path: 'activities',
    loadChildren: () => import('./activities/activities.module').then(m => m.ActivitiesModule)
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
