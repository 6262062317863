<div class="content-block">
    <app-header-banner [data]="banner"></app-header-banner>
    <div class="container" style="margin-top: 10px">
        <main id="main">
            <section class="fadeInUp section-bg wow" style="visibility: visible; animation-name: fadeInUp;">
                <div class="container">
                    <header class="section-header">
                        <h3>GRIEVANCE REDRESSAL CELL</h3>
                    </header>As per the AICTE guideline the college Gender Sensitization Cell is form to deal with grievances regarding girl’s students and female staff members of the college. The functions of the cell are purely safeguarding the rights of female
                    students, faculty and staff members and also to provide a platform for listening to complaints.
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <th scope="col">Sr. No.</th>
                                <th scope="col">Name</th>
                                <th scope="col">Designation</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">1</th>
                                <td>Dr. Dipti Bipinchandra Ruikar (Deshmukh), Principal PRPP Pharmacy College</td>
                                <td>Chairman</td>
                            </tr>
                            <tr>
                                <th scope="row">2</th>
                                <td>Mr. Shreyaskumar Patil</td>
                                <td>Vice Chairman</td>
                            </tr>
                            <tr>
                                <th scope="row">3</th>
                                <td>Adv. Pravin M. Mohod, NGO Representative</td>
                                <td>Lawyer</td>
                            </tr>
                            <tr>
                                <th scope="row">4</th>
                                <td>Mr. Prasad R. Deshmukh, Male faculty Representative</td>
                                <td>Member</td>
                            </tr>

                            <tr>
                                <th scope="row">5</th>
                                <td>Ms. Gauri S. Baraskar, Women’s faculty Representative</td>
                                <td>Member</td>
                            </tr>
                            <tr>
                                <th scope="row">6</th>
                                <td>Mr. Sandip M. Narad, Gents Non Teaching Representative</td>
                                <td>Member</td>
                            </tr>
                            <tr>
                                <th scope="row">7</th>
                                <td>Ms. Minal P. Deshmukh, Women’s Non Teaching Representative</td>
                                <td>Member</td>
                            </tr>
                            <tr>
                                <th scope="row">8</th>
                                <td>B. Pharm Girls Students Representative</td>
                                <td>Member</td>
                            </tr>
                            <tr>
                                <th scope="row">9</th>
                                <td>D. Pharm Girls Students Representative</td>
                                <td>Member</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section><br><br><br>
        </main>
    </div>
</div>