import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-activity-details',
  templateUrl: './activity-details.component.html',
  styleUrls: ['./activity-details.component.css']
})
export class ActivityDetailsComponent implements OnInit {

  activity_data: any 
  // = {
  //   "id": 5,
  //   "title": "Concrete Cube Testing",
  //   "sub_title": "Posted by civilwebadmin | Mar 29, 2022 | 2021-2022",
  //   "content": "<div> <p>Testing of Concrete Cube for the Client ”Renew Sun Renewables Pvt. Ltd.”</p> </div>",
  //   "short_content": "Testing of Concrete Cube for the Client ”Renew Sun Renewables Pvt. Ltd.”",
  //   "banner": "assets/images/activities/5.jpg",
  //   "images_data": [{
  //     "img": "assets/images/activities/civil-other/8.png"
  //   }]
  // };

  constructor(@Inject(MAT_DIALOG_DATA) private data: any,
  public dialogRef: MatDialogRef<ActivityDetailsComponent>) { 
    if (data) {
      this.activity_data = data.dataActivity;

      console.log(this.activity_data)
    }
  }

  ngOnInit(): void {
  }

}
