<div class="section-full wow fadeIn" data-wow-delay="0.4s">
    <div class="container">
        <div class="section-content m-b30 row">
            <div class="item owl-new col-md-6">
                <div class="testimonial-8">
                    <div class="testimonial-text">
                        <p>It is our firm belief that Education should be complete, must be humane; it must include not
                            only the training of the intellect but also the refinement of the heart and the discipline
                            of the spirit.</p>
                    </div>
                    <div class="testimonial-detail clearfix">
                        <div class="testimonial-pic radius shadow"><img src="assets/images/chairman.png"
                                width="100" height="100" alt=""></div>
                        <h5 class="testimonial-name m-t0 m-b5">Shri. Pravinkumar R. Pote</h5> <span
                            class="testimonial-position">Chairman, P. R. Pote Patil Education & Welfare Trust's Group of
                            Institutions, Amravati. & MLC, Amravati, Ex State Minister (MS)</span>
                    </div>
                </div>
            </div>
            <div class="item owl-new-color col-md-6">
                <div class="testimonial-8">
                    <div class="testimonial-text testimonial-text-new">
                        <p>The batches of students who have been heading out every year for over a decade have been a
                            source of satisfaction. Proud to see that they are at the forefront of various fields. It is
                            gratifying to see that the students are brightening their lives from the guidance of eminent
                            guest visiting every year in Techelons, free foreign study tours, technical workshops as
                            well as guidance from highly educated professors.</p>
                    </div>
                    <div class="testimonial-detail clearfix">
                        <div class="testimonial-pic radius shadow"><img src="assets/images/vice-chairman.png"
                                width="100" height="100" alt=""></div>
                        <h5 class="testimonial-name m-t0 m-b5">Shri. Shreyaskumar Patil</h5> <span
                            class="testimonial-position">Vice Chairman, P. R. Pote Patil Education & Welfare Trust’s
                            Group of Institutions, Amravati</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-content m-b30 row">
            <div class="item owl-new col-md-6">
                <div class="testimonial-8">
                    <div class="testimonial-text">
                        <p>Today’s era has far more cutting-edge innovations due to the advances in Science &
                            Technology. Along with Intelligence, we need awareness about our social and cultural
                            responsibilities. In the present scenario, Engineers have to possess multi-disciplinary
                            knowledge to function in the society. P. R. Pote Patil College of Engineering& Management
                            have been instrumental in imparting Quality Education with the help of its state-of-the-art
                            Infrastructure and dedicated Faculty members [...]</p>
                    </div>
                    <div class="testimonial-detail clearfix">
                        <div class="testimonial-pic radius shadow"><img src="assets/images/principal.png"
                                width="100" height="100" alt=""></div>
                        <h5 class="testimonial-name m-t0 m-b5">Dr. D. T. Ingole</h5> <span
                            class="testimonial-position">Principal, P. R. Pote Patil College of Engineering and
                            Management, Amravati</span>
                    </div>
                </div>
            </div>
            <div class="item owl-new-color col-md-6">
                <div class="testimonial-8">
                    <div class="testimonial-text testimonial-text-new">
                        <p>Imagination, creativity and value added service to the society are the quintessence of
                            engineering and technological education.</p>
                    </div>
                    <div class="testimonial-detail clearfix">
                        <div class="testimonial-pic radius shadow"><img src="assets/images/vice-principal.jpg"
                                width="100" height="100" alt=""></div>
                        <h5 class="testimonial-name m-t0 m-b5">Dr. Mohammad Zuhair</h5> <span
                            class="testimonial-position">Vice Principal, P. R. Pote Patil College of Engineering &
                            Management, Amravati</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>