<div class="content-block">
    <app-header-banner [data]="banner"></app-header-banner>
    <div class="container" style="margin-top: 10px">
        <div>
            <main id="main" style="margin-top: 1rem;">
                <section class="fadeInUp section-bg wow" style="visibility: visible; animation-name: fadeInUp;">
                    <div class="container">
                        <header class="section-header">
                            <h3>R &amp; D Cell Composition</h3>
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th scope="col">Sr. No.</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Designation</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="row">1</th>
                                        <td>Dr. Dipti Bipinchandra Ruikar (Deshmukh)</td>
                                        <td>Chairperson</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">2</th>
                                        <td>Prof. Prashant Burange</td>
                                        <td>R &amp; D Co-ordinator</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">3</th>
                                        <td>Prof. Vishal Lahane</td>
                                        <td>Member</td>
                                    </tr>
                                </tbody>
                            </table>
                        </header>
                    </div>
                </section>
                <section class="fadeInUp section-bg wow" style="visibility: visible; animation-name: fadeInUp;">
                    <div class="container">
                        <header class="section-header">
                            <h3>The objectives of the R &amp; D cell</h3>To promote and facilitate research activities amongst the faculty and students:<br>
                            <ul>
                                <li>To Collect, Analyze and published the data from faculties.</li>
                                <li>To encourage faculty and students to participate in research projects to improve their technical skills and knowledge.</li>
                                <li>To assist faculty in obtaining research grants from various funding Agencies.</li>
                                <li>To update Google Scholar Citation of each faculty.</li>
                            </ul>
                        </header>
                    </div>
                </section>
                <section class="fadeInUp section-bg wow" style="visibility: visible; animation-name: fadeInUp;">
                    <div class="container">
                        <header class="section-header">
                            <h3>Department wise R &amp; D Thrust areas</h3><b>Pharmaceutics:</b><br>
                            <ul>
                                <li>Formulation and Development</li>
                                <li>Novel Drug Delivery Systems</li>
                                <li>Multiparticulate Drug Delivery System</li>
                                <li>Colon Specific Drug Delivery System</li>
                            </ul><b>Pharmaceutical Chemistry:</b><br>
                            <ul>
                                <li>Molecular Modeling</li>
                                <li>Computer Aided Drug Designing</li>
                                <li>Combinatorial Chemistry</li>
                                <li>Analytical Method Development</li>
                            </ul><b>Pharmacology:</b><br>
                            <ul>
                                <li>Toxicological Evaluation</li>
                                <li>Preliminary Screening of Drug Effect</li>
                                <li>Effective Bioavailability and Bioequivalence Studies</li>
                                <li>In-Vivo Studies</li>
                                <li>Preclinical Studies</li>
                                <li>Adverse Drug Reaction</li>
                            </ul><b>Pharmacognosy:</b><br>
                            <ul>
                                <li>Pharmacognostic Analysis</li>
                                <li>Preliminary Screening of Drug Effect</li>
                                <li>Qualitative and Quantitative Studies</li>
                                <li>Pharmacological Studies of Herbal Drug and Formulation</li>
                                <li>Formulation and Development of Crude Drugs</li>
                                <li>Fingerprint Analysis of Herbal Drugs and Formulations</li>
                            </ul>
                        </header>
                    </div>
                </section>
    
            </main>
        </div>
    </div>
</div>