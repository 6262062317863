import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-icc',
  templateUrl: './icc.component.html',
  styleUrls: ['./icc.component.css']
})
export class IccComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  banner: any = {
    pagetitle: "GRIEVANCE REDRESSAL CELL",
    bg_image: "assets/images/banner/bnr3.jpg",
    title: "GRIEVANCE REDRESSAL CELL",
  }
}
