<div class="content-block">
    <app-header-banner [data]="banner"></app-header-banner>
    <div class="container" style="margin-top: 10px">
        <div>
            <main id="main">
                <section class="fadeInUp section-bg wow" style="visibility: visible; animation-name: fadeInUp;">
                    <div class="container-fluid">
                        <header class="section-header">
                            <h3></h3>
                        </header>
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-md-12">
                                    <div>
                                        <ul class="nav nav-tabs" role="tablist">
                                            <li role="presentation" class="active"><a href="#Section1"
                                                    aria-controls="home" role="tab" data-toggle="tab">2019-20</a></li>
                                            <li role="presentation"><a href="#Section2" aria-controls="messages"
                                                    role="tab" data-toggle="tab">2018-19</a></li>
                                            <li role="presentation"><a href="#Section3" aria-controls="messages"
                                                    role="tab" data-toggle="tab">2017-18</a></li>
                                        </ul>
                                        <div class="tab-content" style="margin-top: 1rem;">
                                            <div class="tab-pane active" role="tabpanel" id="Section1">
                                                <div class="container-fluid">
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div class="di">
                                                                <div style="overflow-x:auto">
                                                                    <table class="table table-bordered" >
                                                                        <thead >
                                                                            <tr>
                                                                                <th style="text-align: center;vertical-align: middle;" scope="col">Sr. No.</th>
                                                                                <th style="text-align: center;vertical-align: middle;" scope="col">Name of Participant</th>
                                                                                <th style="text-align: center;vertical-align: middle;" scope="col">Title of Paper</th>
                                                                                <th style="text-align: center;vertical-align: middle;" scope="col">Name of the
                                                                                    Workshop/Seminar/Conference</th>
                                                                                <th style="text-align: center;vertical-align: middle;" scope="col">Year</th>
                                                                                <th style="text-align: center;vertical-align: middle;" scope="col">Date</th>
                                                                                <th style="text-align: center;vertical-align: middle;" scope="col">Place</th>
                                                                                <th style="text-align: center;vertical-align: middle;" scope="col" style="width:10%">Photos
                                                                                </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <th scope="row">1</th>
                                                                                <td>Kalpak Gajbhiye</td>
                                                                                <td>Medicine Dispensing Box- Blessing
                                                                                    for Society</td>
                                                                                <td>Business Model Competition-RO</td>
                                                                                <td>2019</td>
                                                                                <td>04/09/2019 &amp; 05/09/2019</td>
                                                                                <td>Amravati</td>
                                                                                <td rowspan="10">
                                                                                    <input
                                                                                            class="btn btn-success"
                                                                                            name="poster" type="submit"
                                                                                            value="View Photos"
                                                                                            style="padding-top:60px;padding-bottom:60px">
                                                                                    
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">2</th>
                                                                                <td>Nawaz Hakam</td>
                                                                                <td>Medicine Dispensing Box- Blessing
                                                                                    for Society</td>
                                                                                <td>Business Model Competition-RO</td>
                                                                                <td>2019</td>
                                                                                <td>04/09/2019 &amp; 05/09/2019</td>
                                                                                <td>Amravati</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">3</th>
                                                                                <td>Faizan Dosani Firoz Dosani</td>
                                                                                <td>Falsification of Medicine</td>
                                                                                <td>Business Model Competition-RO</td>
                                                                                <td>2019</td>
                                                                                <td>04/09/2019 &amp; 05/09/2019</td>
                                                                                <td>Amravati</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">4</th>
                                                                                <td>Aman Vyawhare</td>
                                                                                <td>Falsification of Medicine</td>
                                                                                <td>Business Model Competition-RO</td>
                                                                                <td>2019</td>
                                                                                <td>04/09/2019 &amp; 05/09/2019</td>
                                                                                <td>Amravati</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">5</th>
                                                                                <td>Suyog Bhure</td>
                                                                                <td>Portable Tablet Machine</td>
                                                                                <td>Business Model Competition-RO</td>
                                                                                <td>2019</td>
                                                                                <td>04/09/2019 &amp; 05/09/2019</td>
                                                                                <td>Amravati</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">6</th>
                                                                                <td>Renuka Mamurkar</td>
                                                                                <td>Portable Tablet Machine</td>
                                                                                <td>Business Model Competition-RO</td>
                                                                                <td>2019</td>
                                                                                <td>04/09/2019 &amp; 05/09/2019</td>
                                                                                <td>Amravati</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">7</th>
                                                                                <td>Sakshi Shewatkar</td>
                                                                                <td>Design of App for distribution of
                                                                                    Sanitary Napkins</td>
                                                                                <td>Business Model Competition-RO</td>
                                                                                <td>2019</td>
                                                                                <td>04/09/2019 &amp; 05/09/2019</td>
                                                                                <td>Amravati</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">8</th>
                                                                                <td>Samiksha Burange</td>
                                                                                <td>Design of App for distribution of
                                                                                    Sanitary Napkins</td>
                                                                                <td>Business Model Competition-RO</td>
                                                                                <td>2019</td>
                                                                                <td>04/09/2019 &amp; 05/09/2019</td>
                                                                                <td>Amravati</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">9</th>
                                                                                <td>Vaishnavi Bhutada</td>
                                                                                <td>Nanotheranostics: A Synergistic
                                                                                    Effect of Diagnosis with Treatment
                                                                                    for Incurable diseases.</td>
                                                                                <td>Business Model Competition-RO</td>
                                                                                <td>2019</td>
                                                                                <td>04/09/2019 &amp; 05/09/2019</td>
                                                                                <td>Amravati</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">10</th>
                                                                                <td>Sneha Rathod</td>
                                                                                <td>Nanotheranostics: A Synergistic
                                                                                    Effect of Diagnosis with Treatment
                                                                                    for Incurable diseases.</td>
                                                                                <td>Business Model Competition-RO</td>
                                                                                <td>2019</td>
                                                                                <td>04/09/2019 &amp; 05/09/2019</td>
                                                                                <td>Amravati</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">11</th>
                                                                                <td>Vaishnavi Ladhake</td>
                                                                                <td>Preparation and Evaluation of Oral
                                                                                    Dispersible film of Bilastine using
                                                                                    Pullulan</td>
                                                                                <td>Poster Presentation: IPC</td>
                                                                                <td>2019</td>
                                                                                <td>20/12/2019 &amp; 22/12/2019</td>
                                                                                <td>Chennai</td>
                                                                                <td rowspan="21">
                                                                                    <a href="poster2.php"><input
                                                                                                class="btn btn-success"
                                                                                                name="poster"
                                                                                                type="submit"
                                                                                                value="View Photos"></a>
                                                                                    
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">12</th>
                                                                                <td>Samiksha Kadu</td>
                                                                                <td>Preparation and Evaluation of Oral
                                                                                    Dispersible film of Bilastine using
                                                                                    Pullulan</td>
                                                                                <td>Poster Presentation: IPC</td>
                                                                                <td>2019</td>
                                                                                <td>20/12/2019 &amp; 22/12/2019</td>
                                                                                <td>Chennai</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">13</th>
                                                                                <td>Vaishnavi Tayade</td>
                                                                                <td>Preparation and Evaluation of Oral
                                                                                    Dispersible film of Bilastine using
                                                                                    Pullulan</td>
                                                                                <td>Poster Presentation: IPC</td>
                                                                                <td>2019</td>
                                                                                <td>20/12/2019 &amp; 22/12/2019</td>
                                                                                <td>Chennai</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">14</th>
                                                                                <td>Shweta Gawande</td>
                                                                                <td>Preparation and Evaluation of Oral
                                                                                    Dispersible film of Bilastine using
                                                                                    Pullulan</td>
                                                                                <td>Poster Presentation: IPC</td>
                                                                                <td>2019</td>
                                                                                <td>20/12/2019 &amp; 22/12/2019</td>
                                                                                <td>Chennai</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">15</th>
                                                                                <td>Juili Satao</td>
                                                                                <td>Preparation and Evaluation of
                                                                                    Benidipine Hydrochloride
                                                                                    Microballoons</td>
                                                                                <td>Poster Presentation: IPC</td>
                                                                                <td>2019</td>
                                                                                <td>20/12/2019 &amp; 22/12/2019</td>
                                                                                <td>Chennai</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">16</th>
                                                                                <td>Pallavi Wadaskar</td>
                                                                                <td>Preparation and Evaluation of
                                                                                    Benidipine Hydrochloride
                                                                                    Microballoons</td>
                                                                                <td>Poster Presentation: IPC</td>
                                                                                <td>2019</td>
                                                                                <td>20/12/2019 &amp; 22/12/2019</td>
                                                                                <td>Chennai</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">17</th>
                                                                                <td>Aman Bais</td>
                                                                                <td>Preparation and Evaluation of
                                                                                    Benidipine Hydrochloride
                                                                                    Microballoons</td>
                                                                                <td>Poster Presentation: IPC</td>
                                                                                <td>2019</td>
                                                                                <td>20/12/2019 &amp; 22/12/2019</td>
                                                                                <td>Chennai</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">18</th>
                                                                                <td>Sushant Bamburkar</td>
                                                                                <td>Preparation and Evaluation of
                                                                                    Benidipine Hydrochloride
                                                                                    Microballoons</td>
                                                                                <td>Poster Presentation: IPC</td>
                                                                                <td>2019</td>
                                                                                <td>20/12/2019 &amp; 22/12/2019</td>
                                                                                <td>Chennai</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">19</th>
                                                                                <td>Kalapak Gajbhiye</td>
                                                                                <td>Preparation and Characterization of
                                                                                    Oral fast dissolving tablet of
                                                                                    Enalapril Maleate using Fenugreek
                                                                                    Mucilage</td>
                                                                                <td>Poster Presentation: IPC</td>
                                                                                <td>2019</td>
                                                                                <td>20/12/2019 &amp; 22/12/2019</td>
                                                                                <td>Chennai</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">20</th>
                                                                                <td>Nawaz Hakam</td>
                                                                                <td>Preparation and Characterization of
                                                                                    Oral fast dissolving tablet of
                                                                                    Enalapril Maleate using Fenugreek
                                                                                    Mucilage</td>
                                                                                <td>Poster Presentation: IPC</td>
                                                                                <td>2019</td>
                                                                                <td>20/12/2019 &amp; 22/12/2019</td>
                                                                                <td>Chennai</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">21</th>
                                                                                <td>Aman Vyawhare</td>
                                                                                <td>Preparation and Characterization of
                                                                                    Oral fast dissolving tablet of
                                                                                    Enalapril Maleate using Fenugreek
                                                                                    Mucilage</td>
                                                                                <td>Poster Presentation: IPC</td>
                                                                                <td>2019</td>
                                                                                <td>20/12/2019 &amp; 22/12/2019</td>
                                                                                <td>Chennai</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">22</th>
                                                                                <td>Swanand Patharkar</td>
                                                                                <td>Preparation and Characterization of
                                                                                    Oral fast dissolving tablet of
                                                                                    Enalapril Maleate using Fenugreek
                                                                                    Mucilage</td>
                                                                                <td>Poster Presentation: IPC</td>
                                                                                <td>2019</td>
                                                                                <td>20/12/2019 &amp; 22/12/2019</td>
                                                                                <td>Chennai</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">23</th>
                                                                                <td>Gauri Rathod</td>
                                                                                <td>Formulation and Evaluation of
                                                                                    Enteric Coated Tablet of
                                                                                    Antihypertensive effect by using
                                                                                    Nebivolol</td>
                                                                                <td>Poster Presentation: IPC</td>
                                                                                <td>2019</td>
                                                                                <td>20/12/2019 &amp; 22/12/2019</td>
                                                                                <td>Chennai</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">24</th>
                                                                                <td>Sakshi Shewatkar</td>
                                                                                <td>Optimization and Standardization of
                                                                                    Delayed Release tablet containing
                                                                                    Doxycycline Hyclate</td>
                                                                                <td>Poster Presentation: IPC</td>
                                                                                <td>2019</td>
                                                                                <td>20/12/2019 &amp; 22/12/2019</td>
                                                                                <td>Chennai</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">25</th>
                                                                                <td>Saurabh Thakre</td>
                                                                                <td>Optimization and Standardization of
                                                                                    Delayed Release tablet containing
                                                                                    Doxycycline Hyclate</td>
                                                                                <td>Poster Presentation: IPC</td>
                                                                                <td>2019</td>
                                                                                <td>20/12/2019 &amp; 22/12/2019</td>
                                                                                <td>Chennai</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">26</th>
                                                                                <td>Shweta Warghat</td>
                                                                                <td>Optimization and Standardization of
                                                                                    Delayed Release tablet containing
                                                                                    Doxycycline Hyclate</td>
                                                                                <td>Poster Presentation: IPC</td>
                                                                                <td>2019</td>
                                                                                <td>20/12/2019 &amp; 22/12/2019</td>
                                                                                <td>Chennai</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">27</th>
                                                                                <td>Suyog Bhure</td>
                                                                                <td>Optimization and Standardization of
                                                                                    Delayed Release tablet containing
                                                                                    Doxycycline Hyclate</td>
                                                                                <td>Poster Presentation: IPC</td>
                                                                                <td>2019</td>
                                                                                <td>20/12/2019 &amp; 22/12/2019</td>
                                                                                <td>Chennai</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">28</th>
                                                                                <td>Pallavi Kharkar</td>
                                                                                <td>Formulation and Evaluation of
                                                                                    polymeric nanoparticle gel of
                                                                                    methoxsalen for the anti/psoriac
                                                                                    agent</td>
                                                                                <td>Poster Presentation: IPC</td>
                                                                                <td>2019</td>
                                                                                <td>20/12/2019 &amp; 22/12/2019</td>
                                                                                <td>Chennai</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">29</th>
                                                                                <td>Madhuri Tanpure</td>
                                                                                <td>Formulation and Evaluation of
                                                                                    polymeric nanoparticle gel of
                                                                                    methoxsalen for the anti/psoriac
                                                                                    agent</td>
                                                                                <td>Poster Presentation: IPC</td>
                                                                                <td>2019</td>
                                                                                <td>20/12/2019 &amp; 22/12/2019</td>
                                                                                <td>Chennai</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">30</th>
                                                                                <td>Krutika Sonar</td>
                                                                                <td>Formulation and Evaluation of
                                                                                    polymeric nanoparticle gel of
                                                                                    methoxsalen for the anti/psoriac
                                                                                    agent</td>
                                                                                <td>Poster Presentation: IPC</td>
                                                                                <td>2019</td>
                                                                                <td>20/12/2019 &amp; 22/12/2019</td>
                                                                                <td>Chennai</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">31</th>
                                                                                <td>Mohini Murkute</td>
                                                                                <td>Formulation and Evaluation of
                                                                                    polymeric nanoparticle gel of
                                                                                    methoxsalen for the anti/psoriac
                                                                                    agent</td>
                                                                                <td>Poster Presentation: IPC</td>
                                                                                <td>2019</td>
                                                                                <td>20/12/2019 &amp; 22/12/2019</td>
                                                                                <td>Chennai</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">32</th>
                                                                                <td>Vaishnavi Ladhake</td>
                                                                                <td>Preparation and Evaluation of Oral
                                                                                    Dispersible film of Bilastine using
                                                                                    Pullulan</td>
                                                                                <td>Poster Presentation: Avishkar</td>
                                                                                <td>2020</td>
                                                                                <td>07/01/2020</td>
                                                                                <td>Amravati</td>
                                                                                <td rowspan="45">
                                                                                    <a href="poster1.php"><input
                                                                                                class="btn btn-success"
                                                                                                name="poster"
                                                                                                type="submit"
                                                                                                value="View Photos"></a>
                                                                                    
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">33</th>
                                                                                <td>Shweta Gawande</td>
                                                                                <td>Preparation and Evaluation of Oral
                                                                                    Dispersible film of Bilastine using
                                                                                    Pullulan</td>
                                                                                <td>Poster Presentation: Avishkar</td>
                                                                                <td>2020</td>
                                                                                <td>07/01/2020</td>
                                                                                <td>Amravati</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">34</th>
                                                                                <td>Swanand Patharkar</td>
                                                                                <td>Preparation and Evaluation of Oral
                                                                                    fast dissolving tablets of Enalapril
                                                                                    Maleate using Fenugreek Mucilage
                                                                                </td>
                                                                                <td>Poster Presentation: Avishkar</td>
                                                                                <td>2020</td>
                                                                                <td>07/01/2020</td>
                                                                                <td>Amravati</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">35</th>
                                                                                <td>Tejas Deshmukh</td>
                                                                                <td>Preparation and Evaluation of Oral
                                                                                    fast dissolving tablets of Enalapril
                                                                                    Maleate using Fenugreek Mucilage
                                                                                </td>
                                                                                <td>Poster Presentation: Avishkar</td>
                                                                                <td>2020</td>
                                                                                <td>07/01/2020</td>
                                                                                <td>Amravati</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">36</th>
                                                                                <td>Krutika Sonar</td>
                                                                                <td>Bronchodilator activity of
                                                                                    <i>Lawsoniainermis</i> Linn.</td>
                                                                                <td>Poster Presentation: Avishkar</td>
                                                                                <td>2020</td>
                                                                                <td>07/01/2020</td>
                                                                                <td>Amravati</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">37</th>
                                                                                <td>Madhuri Tanpure</td>
                                                                                <td>Bronchodilator activity of
                                                                                    <i>Lawsoniainermis</i> Linn.</td>
                                                                                <td>Poster Presentation: Avishkar</td>
                                                                                <td>2020</td>
                                                                                <td>07/01/2020</td>
                                                                                <td>Amravati</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">38</th>
                                                                                <td>Gauri Dhule</td>
                                                                                <td>Preparation and Evaluation of Herbal
                                                                                    handwash to control hand borne
                                                                                    disease</td>
                                                                                <td>Poster Presentation: Avishkar</td>
                                                                                <td>2020</td>
                                                                                <td>07/01/2020</td>
                                                                                <td>Amravati</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">39</th>
                                                                                <td>Akshada Chauhan</td>
                                                                                <td>Preparation and Evaluation of Herbal
                                                                                    handwash to control hand borne
                                                                                    disease</td>
                                                                                <td>Poster Presentation: Avishkar</td>
                                                                                <td>2020</td>
                                                                                <td>07/01/2020</td>
                                                                                <td>Amravati</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">40</th>
                                                                                <td>Samruddhi Ingle</td>
                                                                                <td>Jelly beans for tooth ache</td>
                                                                                <td>Poster Presentation: Avishkar</td>
                                                                                <td>2020</td>
                                                                                <td>07/01/2020</td>
                                                                                <td>Amravati</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">41</th>
                                                                                <td>Samiksha Uttarwar</td>
                                                                                <td>Jelly beans for tooth ache</td>
                                                                                <td>Poster Presentation: Avishkar</td>
                                                                                <td>2020</td>
                                                                                <td>07/01/2020</td>
                                                                                <td>Amravati</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">42</th>
                                                                                <td>Vedant Radke</td>
                                                                                <td>Herbal Chewing Gum for migraine and
                                                                                    stress management</td>
                                                                                <td>Poster Presentation: Avishkar</td>
                                                                                <td>2020</td>
                                                                                <td>07/01/2020</td>
                                                                                <td>Amravati</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">43</th>
                                                                                <td>Jay Ade</td>
                                                                                <td>Herbal Chewing Gum for migraine and
                                                                                    stress management</td>
                                                                                <td>Poster Presentation: Avishkar</td>
                                                                                <td>2020</td>
                                                                                <td>07/01/2020</td>
                                                                                <td>Amravati</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">44</th>
                                                                                <td>Maithili Amle</td>
                                                                                <td>Formulation and Evaluation of Extend
                                                                                    Release tablet containing
                                                                                    Anti-depressant agent</td>
                                                                                <td>Poster Presentation: Avishkar</td>
                                                                                <td>2020</td>
                                                                                <td>07/01/2020</td>
                                                                                <td>Amravati</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">45</th>
                                                                                <td>Sakshi Wankhade</td>
                                                                                <td>Formulation and Evaluation of Extend
                                                                                    Release tablet containing
                                                                                    Anti-depressant agent</td>
                                                                                <td>Poster Presentation: Avishkar</td>
                                                                                <td>2020</td>
                                                                                <td>07/01/2020</td>
                                                                                <td>Amravati</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">46</th>
                                                                                <td>Vaishnavi Bhutada</td>
                                                                                <td>Nanotheranostics: A Synergistic
                                                                                    Effect of Diagnosis with Treatment
                                                                                    for Incurable diseases.</td>
                                                                                <td>Poster Presentation: Avishkar</td>
                                                                                <td>2020</td>
                                                                                <td>07/01/2020</td>
                                                                                <td>Amravati</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">47</th>
                                                                                <td>Sneha Rathod</td>
                                                                                <td>Nanotheranostics: A Synergistic
                                                                                    Effect of Diagnosis with Treatment
                                                                                    for Incurable diseases.</td>
                                                                                <td>Poster Presentation: Avishkar</td>
                                                                                <td>2020</td>
                                                                                <td>07/01/2020</td>
                                                                                <td>Amravati</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">48</th>
                                                                                <td>Manali Nemade</td>
                                                                                <td>Pregnancy and self-medication</td>
                                                                                <td>Poster Presentation: Avishkar</td>
                                                                                <td>2020</td>
                                                                                <td>07/01/2020</td>
                                                                                <td>Amravati</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">49</th>
                                                                                <td>Apurva Fasate</td>
                                                                                <td>Pregnancy and self-medication</td>
                                                                                <td>Poster Presentation: Avishkar</td>
                                                                                <td>2020</td>
                                                                                <td>07/01/2020</td>
                                                                                <td>Amravati</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">50</th>
                                                                                <td>Shweta Wathore</td>
                                                                                <td>New Generation of Medicine for
                                                                                    diabetic treatment: Nanomedicine
                                                                                </td>
                                                                                <td>Poster Presentation: Avishkar</td>
                                                                                <td>2020</td>
                                                                                <td>07/01/2020</td>
                                                                                <td>Amravati</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">51</th>
                                                                                <td>Aditi Tikait</td>
                                                                                <td>New Generation of Medicine for
                                                                                    diabetic treatment: Nanomedicine
                                                                                </td>
                                                                                <td>Poster Presentation: Avishkar</td>
                                                                                <td>2020</td>
                                                                                <td>07/01/2020</td>
                                                                                <td>Amravati</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">52</th>
                                                                                <td>Mrunali Dhakare</td>
                                                                                <td>Neutraceuticals: Treatment and
                                                                                    Prevention of disease</td>
                                                                                <td>Poster Presentation: Avishkar</td>
                                                                                <td>2020</td>
                                                                                <td>07/01/2020</td>
                                                                                <td>Amravati</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">53</th>
                                                                                <td>Sakshi Jari</td>
                                                                                <td>Neutraceuticals: Treatment and
                                                                                    Prevention of disease</td>
                                                                                <td>Poster Presentation: Avishkar</td>
                                                                                <td>2020</td>
                                                                                <td>07/01/2020</td>
                                                                                <td>Amravati</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">54</th>
                                                                                <td>Shivani Kotamkar</td>
                                                                                <td>Use of plants in Antibiotics
                                                                                    Resistance.</td>
                                                                                <td>Poster Presentation: Avishkar</td>
                                                                                <td>2020</td>
                                                                                <td>07/01/2020</td>
                                                                                <td>Amravati</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">55</th>
                                                                                <td>Sakshi Gujarkar</td>
                                                                                <td>Use of plants in Antibiotics
                                                                                    Resistance.</td>
                                                                                <td>Poster Presentation: Avishkar</td>
                                                                                <td>2020</td>
                                                                                <td>07/01/2020</td>
                                                                                <td>Amravati</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">56</th>
                                                                                <td>Samiksha Kadu</td>
                                                                                <td>Management of Healthy lifestyle by
                                                                                    daily intake of natural antioxidants
                                                                                </td>
                                                                                <td>Poster Presentation: Avishkar</td>
                                                                                <td>2020</td>
                                                                                <td>07/01/2020</td>
                                                                                <td>Amravati</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">57</th>
                                                                                <td>Vaishnavi Tayde</td>
                                                                                <td>Management of Healthy lifestyle by
                                                                                    daily intake of natural antioxidants
                                                                                </td>
                                                                                <td>Poster Presentation: Avishkar</td>
                                                                                <td>2020</td>
                                                                                <td>07/01/2020</td>
                                                                                <td>Amravati</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">58</th>
                                                                                <td>Krushna Chandak</td>
                                                                                <td>To study antibiotic effect of spices
                                                                                    as drinking water additives.</td>
                                                                                <td>Poster Presentation: Avishkar</td>
                                                                                <td>2020</td>
                                                                                <td>07/01/2020</td>
                                                                                <td>Amravati</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">59</th>
                                                                                <td>Hitesh Khodskar</td>
                                                                                <td>To study antibiotic effect of spices
                                                                                    as drinking water additives.</td>
                                                                                <td>Poster Presentation: Avishkar</td>
                                                                                <td>2020</td>
                                                                                <td>07/01/2020</td>
                                                                                <td>Amravati</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">60</th>
                                                                                <td>Gajanan Pawar</td>
                                                                                <td>Novel drug delivery treatment for
                                                                                    herbal formulation in cancer
                                                                                    treatment</td>
                                                                                <td>Poster Presentation: Avishkar</td>
                                                                                <td>2020</td>
                                                                                <td>07/01/2020</td>
                                                                                <td>Amravati</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">61</th>
                                                                                <td>Deepraje Wankhade</td>
                                                                                <td>Novel drug delivery treatment for
                                                                                    herbal formulation in cancer
                                                                                    treatment</td>
                                                                                <td>Poster Presentation: Avishkar</td>
                                                                                <td>2020</td>
                                                                                <td>07/01/2020</td>
                                                                                <td>Amravati</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">62</th>
                                                                                <td>Gauri Rathod</td>
                                                                                <td>Formulation and Evaluation of
                                                                                    Mucoadhesive film</td>
                                                                                <td>Poster Presentation: Avishkar</td>
                                                                                <td>2020</td>
                                                                                <td>07/01/2020</td>
                                                                                <td>Amravati</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">63</th>
                                                                                <td>Diksha Ghorpade</td>
                                                                                <td>Formulation and Evaluation of
                                                                                    Mucoadhesive film</td>
                                                                                <td>Poster Presentation: Avishkar</td>
                                                                                <td>2020</td>
                                                                                <td>07/01/2020</td>
                                                                                <td>Amravati</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">64</th>
                                                                                <td>Shweta Warghat</td>
                                                                                <td>Formulation and Optimization of
                                                                                    Delayed Release tablet containing
                                                                                    antibacterial agent</td>
                                                                                <td>Poster Presentation: Avishkar</td>
                                                                                <td>2020</td>
                                                                                <td>07/01/2020</td>
                                                                                <td>Amravati</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">65</th>
                                                                                <td>Saurabh Thakare</td>
                                                                                <td>Formulation and Optimization of
                                                                                    Delayed Release tablet containing
                                                                                    antibacterial agent</td>
                                                                                <td>Poster Presentation: Avishkar</td>
                                                                                <td>2020</td>
                                                                                <td>07/01/2020</td>
                                                                                <td>Amravati</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">66</th>
                                                                                <td>Pallavi Kharkar</td>
                                                                                <td>Herbal Buccal Tablet/ Alternative
                                                                                    treatment for insomnia</td>
                                                                                <td>Poster Presentation: Avishkar</td>
                                                                                <td>2020</td>
                                                                                <td>07/01/2020</td>
                                                                                <td>Amravati</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">67</th>
                                                                                <td>Mohini Murkute</td>
                                                                                <td>Herbal Buccal Tablet/ Alternative
                                                                                    treatment for insomnia</td>
                                                                                <td>Poster Presentation: Avishkar</td>
                                                                                <td>2020</td>
                                                                                <td>07/01/2020</td>
                                                                                <td>Amravati</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">68</th>
                                                                                <td>Apurva Deshmukh</td>
                                                                                <td>Review on Gokhru and recent
                                                                                    pharmaceutical</td>
                                                                                <td>Poster Presentation: Avishkar</td>
                                                                                <td>2020</td>
                                                                                <td>07/01/2020</td>
                                                                                <td>Amravati</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">69</th>
                                                                                <td>Aachal Kolhe</td>
                                                                                <td>Review on Gokhru and recent
                                                                                    pharmaceutical</td>
                                                                                <td>Poster Presentation: Avishkar</td>
                                                                                <td>2020</td>
                                                                                <td>07/01/2020</td>
                                                                                <td>Amravati</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">70</th>
                                                                                <td>Juili Satao</td>
                                                                                <td>Preparation and Evaluation of
                                                                                    Benidipine Hydrochloride
                                                                                    Microballoons</td>
                                                                                <td>Poster Presentation: Avishkar</td>
                                                                                <td>2020</td>
                                                                                <td>07/01/2020</td>
                                                                                <td>Amravati</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">71</th>
                                                                                <td>Aman Bais</td>
                                                                                <td>Preparation and Evaluation of
                                                                                    Benidipine Hydrochloride
                                                                                    Microballoons</td>
                                                                                <td>Poster Presentation: Avishkar</td>
                                                                                <td>2020</td>
                                                                                <td>07/01/2020</td>
                                                                                <td>Amravati</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">72</th>
                                                                                <td>Kalpak Gajbhiye</td>
                                                                                <td>Medicine Dispensing Box- Blessing
                                                                                    for Society</td>
                                                                                <td>Poster Presentation: Avishkar</td>
                                                                                <td>2020</td>
                                                                                <td>07/01/2020</td>
                                                                                <td>Amravati</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">73</th>
                                                                                <td>Nawaz Hakam</td>
                                                                                <td>Medicine Dispensing Box- Blessing
                                                                                    for Society</td>
                                                                                <td>Poster Presentation: Avishkar</td>
                                                                                <td>2020</td>
                                                                                <td>07/01/2020</td>
                                                                                <td>Amravati</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">74</th>
                                                                                <td>Faizan Dosani</td>
                                                                                <td>Falsification of Medicine</td>
                                                                                <td>Poster Presentation: Avishkar</td>
                                                                                <td>2020</td>
                                                                                <td>07/01/2020</td>
                                                                                <td>Amravati</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">75</th>
                                                                                <td>Aman Vyawhare</td>
                                                                                <td>Falsification of Medicine</td>
                                                                                <td>Poster Presentation: Avishkar</td>
                                                                                <td>2020</td>
                                                                                <td>07/01/2020</td>
                                                                                <td>Amravati</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">76</th>
                                                                                <td>Suyog Bhure</td>
                                                                                <td>Portable Tablet Machine</td>
                                                                                <td>Poster Presentation: Avishkar</td>
                                                                                <td>2020</td>
                                                                                <td>07/01/2020</td>
                                                                                <td>Amravati</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">77</th>
                                                                                <td>Renuka Mamurkar</td>
                                                                                <td>Portable Tablet Machine</td>
                                                                                <td>Poster Presentation: Avishkar</td>
                                                                                <td>2020</td>
                                                                                <td>07/01/2020</td>
                                                                                <td>Amravati</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">78</th>
                                                                                <td>Sakshi Shewatkar</td>
                                                                                <td>Design of App for distribution of
                                                                                    Sanitary Napkins</td>
                                                                                <td>Poster Presentation: Avishkar</td>
                                                                                <td>2020</td>
                                                                                <td>07/01/2020</td>
                                                                                <td>Amravati</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">79</th>
                                                                                <td>Samiksha Burange</td>
                                                                                <td>Design of App for distribution of
                                                                                    Sanitary Napkins</td>
                                                                                <td>Poster Presentation: Avishkar</td>
                                                                                <td>2020</td>
                                                                                <td>07/01/2020</td>
                                                                                <td>Amravati</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">80</th>
                                                                                <td>Kalpak Gajbhiye</td>
                                                                                <td>Medicine Dispensing Box- Blessing
                                                                                    for Society</td>
                                                                                <td>Poster Presentation: Avishkar</td>
                                                                                <td>2020</td>
                                                                                <td>07/01/2020</td>
                                                                                <td>Amravati</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">81</th>
                                                                                <td>Faizan Dosani Firoz Dosani</td>
                                                                                <td>Medicine Dispensing Box- Blessing
                                                                                    for Society</td>
                                                                                <td>Poster Presentation: Avishkar</td>
                                                                                <td>2020</td>
                                                                                <td>07/01/2020</td>
                                                                                <td>Amravati</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">82</th>
                                                                                <td>Nawaz Hakam</td>
                                                                                <td>Medicine Dispensing Box- Blessing
                                                                                    for Society</td>
                                                                                <td>Poster Presentation: Avishkar</td>
                                                                                <td>2020</td>
                                                                                <td>07/01/2020</td>
                                                                                <td>Amravati</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">83</th>
                                                                                <td>Aman Bais</td>
                                                                                <td>Medicine Dispensing Box- Blessing
                                                                                    for Society</td>
                                                                                <td>Poster Presentation: Avishkar</td>
                                                                                <td>2020</td>
                                                                                <td>07/01/2020</td>
                                                                                <td>Amravati</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane" role="tabpanel" id="Section2">
                                                <div class="container">
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div class="di">
                                                                <div style="overflow-x:auto">
                                                                    <table class="table table-bordered" >
                                                                        <thead >
                                                                            <tr>
                                                                                <th scope="col" style="text-align: center;vertical-align: middle;">Sr. No.</th>
                                                                                <th scope="col" style="text-align: center;vertical-align: middle;">Name of Participant</th>
                                                                                <th scope="col" style="text-align: center;vertical-align: middle;">Title of Paper</th>
                                                                                <th scope="col" style="text-align: center;vertical-align: middle;">Name of the
                                                                                    Workshop/Seminar/Conference</th>
                                                                                <th scope="col" style="text-align: center;vertical-align: middle;">Year</th>
                                                                                <th scope="col" style="text-align: center;vertical-align: middle;">Date</th>
                                                                                <th scope="col" style="text-align: center;vertical-align: middle;">Place</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <th scope="row">1</th>
                                                                                <td>Gayatri M. Polakhre</td>
                                                                                <td>Formulation of Natural Gum based on
                                                                                    Beads for delivery of
                                                                                    Anti-inflammatory drug</td>
                                                                                <td>Poster Presentation: Avishkar</td>
                                                                                <td>2018</td>
                                                                                <td>26/12/2018</td>
                                                                                <td>Amravati</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">2</th>
                                                                                <td>Aishwarya S.Dongre</td>
                                                                                <td>Formulation of Natural Gum based on
                                                                                    Beads for delivery of
                                                                                    Anti-inflammatory drug</td>
                                                                                <td>Poster Presentation: Avishkar</td>
                                                                                <td>2018</td>
                                                                                <td>26/12/2018</td>
                                                                                <td>Amravati</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">3</th>
                                                                                <td>Diksha Ghorpade</td>
                                                                                <td>Formulation of Natural Gum Based on
                                                                                    films for delivery of
                                                                                    Antihypertensive agent</td>
                                                                                <td>Poster Presentation: Avishkar</td>
                                                                                <td>2018</td>
                                                                                <td>26/12/2018</td>
                                                                                <td>Amravati</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">4</th>
                                                                                <td>Faizan F. Dosani</td>
                                                                                <td>Formulation of Natural Gum Based on
                                                                                    films for delivery of
                                                                                    Antihypertensive agent</td>
                                                                                <td>Poster Presentation: Avishkar</td>
                                                                                <td>2018</td>
                                                                                <td>26/12/2018</td>
                                                                                <td>Amravati</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">5</th>
                                                                                <td>Faizan Dosani Firoz Dosani</td>
                                                                                <td>Falsification of Medicine- Threat to
                                                                                    the Society</td>
                                                                                <td>Poster Presentation: Innospire</td>
                                                                                <td>2019</td>
                                                                                <td>03/03/2019</td>
                                                                                <td>Buldhana</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">6</th>
                                                                                <td>Kalpak Vilas Gajbhiye</td>
                                                                                <td>Falsification of Medicine- Threat to
                                                                                    the Society</td>
                                                                                <td>Poster Presentation: Innospire</td>
                                                                                <td>2019</td>
                                                                                <td>03/03/2019</td>
                                                                                <td>Buldhana</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">7</th>
                                                                                <td>Hrishikesh Lokhande</td>
                                                                                <td>Medicine Dispensing Box- Blessing
                                                                                    for Society</td>
                                                                                <td>Poster Presentation: Innospire</td>
                                                                                <td>2019</td>
                                                                                <td>03/03/2019</td>
                                                                                <td>Buldhana</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">8</th>
                                                                                <td>Abdul Nadeem</td>
                                                                                <td>Medicine Dispensing Box- Blessing
                                                                                    for Society</td>
                                                                                <td>Poster Presentation: Innospire</td>
                                                                                <td>2019</td>
                                                                                <td>03/03/2019</td>
                                                                                <td>Buldhana</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">9</th>
                                                                                <td>Sakshi Shewatkar</td>
                                                                                <td>3D Printing The Future of
                                                                                    Manufacturing Medicine</td>
                                                                                <td>Poster Presentation: Innospire</td>
                                                                                <td>2019</td>
                                                                                <td>03/03/2019</td>
                                                                                <td>Buldhana</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">10</th>
                                                                                <td>Renuka Mamurkar</td>
                                                                                <td>3D Printing The Future of
                                                                                    Manufacturing Medicine</td>
                                                                                <td>Poster Presentation: Innospire</td>
                                                                                <td>2019</td>
                                                                                <td>03/03/2019</td>
                                                                                <td>Buldhana</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane" role="tabpanel" id="Section3">
                                                <div class="container">
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div class="di">
                                                                <div style="overflow-x:auto">
                                                                    <table class="table table-bordered" >
                                                                        <thead >
                                                                            <tr>
                                                                                <th scope="col" style="text-align: center;vertical-align: middle;">Sr. No.</th>
                                                                                <th scope="col" style="text-align: center;vertical-align: middle;">Name of Participant</th>
                                                                                <th scope="col" style="text-align: center;vertical-align: middle;">Title of Paper</th>
                                                                                <th scope="col" style="text-align: center;vertical-align: middle;">Name of the
                                                                                    Workshop/Seminar/Conference</th>
                                                                                <th scope="col" style="text-align: center;vertical-align: middle;">Year</th>
                                                                                <th scope="col" style="text-align: center;vertical-align: middle;">Date</th>
                                                                                <th scope="col" style="text-align: center;vertical-align: middle;">Place</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <th scope="row">1</th>
                                                                                <td>Gayatri M. Polakhre</td>
                                                                                <td>Nanoparticles for Anticancer
                                                                                    Targeting and Treatment</td>
                                                                                <td>Poster Presentation: Avishkar</td>
                                                                                <td>2017</td>
                                                                                <td>14/12/2017</td>
                                                                                <td>Amravati</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">2</th>
                                                                                <td>Rahul R. Rajge</td>
                                                                                <td>Nanoparticles for Anticancer
                                                                                    Targeting and Treatment</td>
                                                                                <td>Poster Presentation: Avishkar</td>
                                                                                <td>2017</td>
                                                                                <td>14/12/2017</td>
                                                                                <td>Amravati</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">3</th>
                                                                                <td>Juili S. Satao</td>
                                                                                <td>Prescription Pattern of
                                                                                    antihypertensive drug in a care
                                                                                    hospital</td>
                                                                                <td>Poster Presentation: Avishkar</td>
                                                                                <td>2017</td>
                                                                                <td>14/12/2017</td>
                                                                                <td>Amravati</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">4</th>
                                                                                <td>Saad Khan Ashfaque Khan</td>
                                                                                <td>Prescription Pattern of
                                                                                    antihypertensive drug in a care
                                                                                    hospital</td>
                                                                                <td>Poster Presentation: Avishkar</td>
                                                                                <td>2017</td>
                                                                                <td>14/12/2017</td>
                                                                                <td>Amravati</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    </div>
</div>