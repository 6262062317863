<div class="content-block">
    <app-header-banner [data]="banner"></app-header-banner>
    <div class="container" style="margin-top: 10px">

        <table class="table table-bordered table-hover">
            <thead>
                <tr>
                    <th scope="col">Sr. No.</th>
                    <th scope="col">Name</th>
                    <th scope="col">Organization / College Position</th>
                    <th scope="col">Designation</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th scope="row">1</th>
                    <td>Hon’ble Shri. Pravinkumar R. Pote</td>
                    <td>Chairman</td>
                    <td>Chairman</td>
                </tr>
                <tr>
                    <th scope="row">2</th>
                    <td>Hon’ble Shri. Shreyas P. Pote</td>
                    <td>Vice-Chairman</td>
                    <td>Vice-Chairman</td>
                </tr>
                <tr>
                    <th scope="row">3</th>
                    <td>Dr. D. T. Ingole</td>
                    <td>Director</td>
                    <td>Member<br> (Nominated by Trust)</td>
                </tr>
                <tr>
                    <th scope="row">4</th>
                    <td>Dr. Mohd Zuhair</td>
                    <td>Expert from Other Institute</td>
                    <td>Member<br> (Nominated by Trust)</td>
                </tr>

                <tr>
                    <th scope="row">5</th>
                    <td>Mr. Prakash Pund</td>
                    <td>Field of Social Service</td>
                    <td>Member<br> (Nominated by Trust)</td>
                </tr>
                <tr>
                    <th scope="row">6</th>
                    <td>Mr. Kishor V. Deshmukh</td>
                    <td>Administrative Officer</td>
                    <td>Member<br> (Nominated by Trust)</td>
                </tr>
                <tr>
                    <th scope="row">7</th>
                    <td>Dr. Prasad R. Deshmukh</td>
                    <td>Head of Department</td>
                    <td>Member<br> (Nominated by Trust)</td>
                </tr>
                <tr>
                    <th scope="row">8</th>
                    <td>Mr. Pankaj H. Chaudhary</td>
                    <td>Teaching Member</td>
                    <td>Member</td>
                </tr>
                <tr>
                    <th scope="row">9</th>
                    <td>Mrs. Dipti A. Bonde</td>
                    <td>Teaching Member</td>
                    <td>Member</td>
                </tr>
                <tr>
                    <th scope="row">10</th>
                    <td>Mr. Mahesh D. Pawar</td>
                    <td>Field of Industry</td>
                    <td>Member</td>
                </tr>
                <tr>
                    <th scope="row">11</th>
                    <td>Student
                        (Pending due to Govt Order)
                    </td>
                    <td>President of Student council</td>
                    <td>Member</td>
                </tr>
                <tr>
                    <th scope="row">12</th>
                    <td>Student
                        (Pending due to Govt Order)
                    </td>
                    <td>Secretary of Student council</td>
                    <td>Member</td>
                </tr>

                <tr>
                    <th scope="row">13</th>
                    <td>Dr. Dipti Bipinchandra Ruikar (Deshmukh)</td>
                    <td>Principal</td>
                    <td>Member Secretary</td>
                </tr>
            </tbody>
        </table>




        <div>

            <div class="head-style-new">
                RESPONSIBILITIES OF THE COLLEGE DEVELOPMENT COMMITTEE
            </div>
            <div class="body-style-new">
                <h1></h1>
                <ol style="text-align: justify;">
                    <li> Prepare an overall comprehensive development plan of the college regarding
                        academic, administrative and infrastructural growth, and enable college to foster excellence
                        in curricular, co-curricular and extra-curricular activities.</li>
                    <li> Decide about the overall teaching programs or annual calendar of the college
                    </li>
                    <li> Recommend to the management about introducing new academic courses and the
                        creation of additional teaching and administrative posts.</li>
                    <li> Take review of the self-financing courses in the college, if any, and make
                        recommendations for their improvement</li>
                    <li> Make specific recommendations to the management to foster academic
                        collaborations to strengthen teaching and research.</li>
                    <li> Make specific recommendations regarding the improvement in teaching and
                        suitable training programs for the employees of the college.</li>
                    <li> Formulate proposals of new expenditure not provided for in the annual financial
                        estimates (budget)</li>
                    <li> Discuss the reports of the Internal Quality Assurance Committee and make
                        suitable recommendations.</li>
                    <li> Recommend the administration about appropriate steps to be taken regarding the
                        discipline, safety and security issues of the college or institution</li>
                    <li> Prepare the annual report on the work done by committee for the year ending on
                        the 30th June and submit the same to the management of such college.</li>
                </ol>
            </div>
        </div>

        <div>

            <div class="head-style-new">
                ROLE OF COLLEGE DEVELOPMENT COMMITTEE
            </div>
            <div class="body-style-new">
                <ol style="text-align: justify;">
                    <li> To prepare an overall comprehensive development plan of the College regarding academic,
                        administrative and infrastructural growth, and enable College to foster excellence in
                        curricular, co-curricular and extra-curricular activities</li>
                    <li> To decide about the overall teaching programmes or annual calendar of the college.</li>
                    <li>Recommend to the management about introducing new academic courses and the creation of
                        additional teaching and administrative posts</li>
                    <li> take review of the self-financing courses in the college, if any, and make recommendations for
                        their improvement</li>
                    <li> Make specific recommendations to the management to foster academic collaborations to strengthen
                        teaching and research.</li>
                    <li>make specific recommendations to the management to foster academic collaborations to strengthen
                        teaching and research</li>
                    <li>make specific recommendations to the management to encourage the use of information and
                        communication technology in teaching and learning process</li>
                    <li> Discuss the reports of the Internal Quality Assurance Committee and make suitable
                        recommendations.</li>
                    <li>make specific recommendations regarding the improvement in teaching and suitable training
                        programs for the employees of the college</li>
                    <li>prepare the annual financial estimates (budget) and financial statements of the college or
                        institution and recommend the same to the management for approval</li>
                    <li>formulate proposals of new expenditure not provided for in the annual financial estimates
                        (budget).</li>
                    <li>make recommendations regarding the students and employees welfare activities in the college or
                        institution</li>
                    <li>discuss the reports of the IQAC and make suitable recommendations</li>
                    <li>frame suitable admissions procedure for different programs by the statutory norms</li>
                    <li>plan major annual events in the college, such as annual day, sports events, cultural events, etc
                    </li>
                    <li>recommend the administration about appropriate steps to be taken regarding the discipline,
                        safety and security issues of the college or institution</li>
                    <li>Consider and make appropriate recommendations on inspection reports, LEC, audit report, report
                        of NAAC , NBA etc.</li>
                    <li>Recommend the distribution of different prizes, medals and awards to the students.</li>
                    <li>Prepare the annual report on the work done by committee for the year ending on the
                        30<sup>th</sup> June and submit the same to the management of such college and the university.
                    </li>
                    <li>Perform such other duties and exercise such other powers as may be entrusted by the management
                        and the university.</li>
                </ol>
            </div>
        </div>
    </div>
</div>