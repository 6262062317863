<div class="section-full content-inner bg-gray wow fadeIn" data-wow-duration="2s" data-wow-delay="0.4s">
    <div class="container">
        <div class="section-head m-b20">
            <h2 class="title" style="color: #F24C3D;font-family: Rockwell !important;font-weight: 500;">Video Gallery</h2>
            <div class="dlab-separator bg-primary" style="background-color: #d6000059 !important;"></div>
        </div>
        
        <div class="row" style="display: flex;justify-content: center;margin-top: 2rem;">
			<div class="col-md-6" >
				<iframe height="350" style="width: 100%;" src="https://www.youtube.com/embed/lGzwKnGvuHs" border="0">
				</iframe>
			</div>
		</div>
        <br>
        
        <div class="row" style="display: flex;justify-content: center;">
            <a routerLink="/youtube-channel2" style="cursor: pointer; font-size: 16px; color: #F24C3D; font-weight: 500;">View More</a>
        </div>
    </div>
</div>