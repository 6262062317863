<div class="content-block">
    <app-header-banner [data]="banner"></app-header-banner>
    <div class="container" style="margin-top: 10px">
       
        <main id="main">
            <section class="fadeInUp section-bg wow" style="visibility: visible; animation-name: fadeInUp;">
                <div class="container">
                    <header class="section-header">
                        <h3>Alumni Association</h3>
                        <div class="table-responsive">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th scope="col">Sr. No.</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Designation</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="row">1</th>
                                        <td>Dr. Dipti Bipinchandra Ruikar (Deshmukh)</td>
                                        <td>Ex. Officio Chairman</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">2</th>
                                        <td>Mr. Sachin Ghayal </td>
                                        <td>President</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">3</th>
                                        <td>Mr. Saurabh Thakare </td>
                                        <td>Vice President</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">4</th>
                                        <td>Miss. Amruta Phuse </td>
                                        <td>Vice President</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">5</th>
                                        <td>Dr. Prasad Deshmukh </td>
                                        <td>Secretory</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">6</th>
                                        <td>Mr. Pankaj Chaudhary </td>
                                        <td>Joint Secretory</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">7</th>
                                        <td>Mr. Rahul Jodh </td>
                                        <td>Joint Secretory</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">8</th>
                                        <td>Mr. Prashant Burange </td>
                                        <td>Treasurer</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">9</th>
                                        <td>Mr. Chaitanya Vikhar </td>
                                        <td>Joint Treasurer</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </header>
                </div>
            </section>

        </main>
        
    </div>
</div>